import { useSession as useSessionBase } from '@alejandro.devop/redux-persistance'

const useSession = (): {
    setKey: (key: keyof SessionType, value: any) => void
    removeKey: (key: keyof SessionType) => void
    setAll: (keys: SessionType) => void
    clear: (defValues?: any) => void
    store: SessionType
} => {
    const { setAll, setKey, removeKey, clear, store } = useSessionBase()
    return {
        setKey: (key: keyof SessionType, value: any) => {
            setKey(key, value)
        },
        removeKey: (key: keyof SessionType) => {
            removeKey(key)
        },
        setAll: (keys: SessionType) => {
            setAll(keys)
        },
        clear,
        store
    }
}

export default useSession
