import { useContext } from 'react'
import { AppContext } from './AppContext'
import { ContextType } from './types'

/**
 * Hook to access the application settings
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
const useAppSettings = (): ContextType => {
    const appSettings = useContext(AppContext) as ContextType
    return appSettings || {}
}

export default useAppSettings
