import * as React from 'react'
import { Props, States, PanelContextType } from './panel-types'
const { useState } = React

const PanelStat = React.createContext({})

/**
 * Base container refactored from the original ColorAdmin theme panel.
 * @param children
 * @param className
 * @param loading
 * @param reload
 * @param onRefresh
 * @param onRemove
 * @param theme
 * @constructor
 */
const PanelBase: React.FC<Props> = ({
    children,
    className,
    loading,
    reload,
    onRefresh,
    onRemove,
    theme,
    expanded
}) => {
    const [states, setStates] = useState<States>({
        expand: false,
        collapse: expanded
    })
    const { expand, collapse } = states

    const toggleExpand = () => {
        setStates({
            ...states,
            expand: !expand
        })
    }
    const toggleCollapse = () => {
        setStates({
            ...states,
            collapse: !collapse
        })
    }

    return (
        <PanelStat.Provider
            value={{
                expand,
                collapse,
                loading,
                reload,
                toggleExpand,
                toggleCollapse,
                onRefresh,
                onRemove
            }}
        >
            <div
                className={
                    'panel panel-' +
                    (theme ? theme : 'inverse') +
                    ' ' +
                    (expand ? 'panel-expand ' : ' ') +
                    (loading || reload ? 'panel-loading ' : ' ') +
                    (className ? className : '') +
                    'panel-fix-z-index'
                }
            >
                {children}
            </div>
        </PanelStat.Provider>
    )
}

/**
 * Simple header for the Panel
 * @param children
 * @param noButton
 * @constructor
 */
const PanelHeader: React.FC<{
    noButton?: boolean
    children?: React.ReactNode
}> = ({ children, noButton }) => {
    return (
        <div className="panel-heading">
            <h4 className="panel-title">{children}</h4>
            {!noButton && (
                <PanelStat.Consumer>
                    {(context) => {
                        const { toggleExpand, onRemove, toggleCollapse, onRefresh } =
                            context as PanelContextType
                        return (
                            <div className="panel-heading-btn">
                                <button
                                    className="btn btn-xs btn-icon btn-circle btn-default"
                                    onClick={toggleExpand}
                                >
                                    <i className="fa fa-expand" />
                                </button>
                                &nbsp;&nbsp;
                                {onRefresh && (
                                    <>
                                        <button
                                            className="btn btn-xs btn-icon btn-circle btn-success"
                                            onClick={onRefresh}
                                        >
                                            <i className="fa fa-redo" />
                                        </button>
                                        &nbsp;&nbsp;
                                    </>
                                )}
                                <button
                                    className="btn btn-xs btn-icon btn-circle btn-warning"
                                    onClick={toggleCollapse}
                                >
                                    <i className="fa fa-minus" />
                                </button>
                                &nbsp;&nbsp;
                                {onRemove && (
                                    <button
                                        className="btn btn-xs btn-icon btn-circle btn-danger"
                                        onClick={onRemove}
                                    >
                                        <i className="fa fa-times" />
                                    </button>
                                )}
                            </div>
                        )
                    }}
                </PanelStat.Consumer>
            )}
        </div>
    )
}

/**
 * Body wrapper for the panel.
 * @param className
 * @param children
 * @constructor
 */
const PanelBody: React.FC<{
    children?: React.ReactNode
    className?: string
}> = ({ className, children }) => {
    return (
        <PanelStat.Consumer>
            {(context) => {
                const { collapse, reload, loading } = context as PanelContextType
                return (
                    <div className={'panel-body ' + (collapse ? 'd-none ' : ' ') + className}>
                        {children}
                        {(reload || loading) && (
                            <div className="panel-loader">
                                <span className="spinner-small" />
                            </div>
                        )}
                    </div>
                )
            }}
        </PanelStat.Consumer>
    )
}

/**
 * Simple footer for the panel.
 * @param children
 * @param className
 * @constructor
 */
const PanelFooter: React.FC<{ children?: React.ReactNode; className?: string }> = ({
    children,
    className
}) => {
    return <div className={'panel-footer ' + className}>{children}</div>
}

export { PanelBase, PanelHeader, PanelBody, PanelFooter }
