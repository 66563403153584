import React from 'react'
import { Alert } from 'reactstrap'

const VSARender: React.FC = () => {
    return (
        <div>
            <Alert>This document is no longer needed</Alert>
        </div>
    )
}

export default VSARender
