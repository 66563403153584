import * as React from 'react'
import { Route } from 'react-router'
import FloatSubMenuList from './float-submenu-list'
import useAppSettings from '@core/app-context/hooks'
const { useState } = React

const FloatSubMenu: React.FC = () => {
    const {
        handleFloatSubMenuOnMouseOut,
        handleFloatSubMenuOnMouseOver,
        pageFloatSubMenuActive,
        pageFloatSubMenuLeft,
        pageFloatSubMenuTop,
        pageFloatSubMenuBottom,
        pageFloatSubMenuArrowTop,
        pageFloatSubMenuArrowBottom,
        pageFloatSubMenuLineTop,
        pageFloatSubMenuLineBottom,
        pageFloatSubMenu,
        handleFloatSubMenuClick
    } = useAppSettings()
    const [states = {}, setStates] = useState<{ [key: string]: number | boolean }>({
        active: -1,
        clicked: -1
    })

    const { active, clicked } = states

    const handleExpand = (e: Event | any, i: number, match: any) => {
        e.preventDefault()
        if (clicked === -1 && match) {
            setStates({
                active: -1,
                clicked: 1
            })
        } else {
            setStates({
                active: active === i ? -1 : i,
                clicked: 1
            })
        }
        setTimeout(() => {
            handleFloatSubMenuClick()
        }, 0)
    }

    return (
        <div
            id="float-sub-menu"
            onMouseOver={(e: any) => handleFloatSubMenuOnMouseOver(e)}
            onMouseOut={(e: any) => handleFloatSubMenuOnMouseOut(e)}
            className={
                'float-sub-menu-container ' + (pageFloatSubMenuActive ? 'd-block' : 'd-none')
            }
            style={{
                left: pageFloatSubMenuLeft,
                top: pageFloatSubMenuTop,
                bottom: pageFloatSubMenuBottom
            }}
        >
            <div
                className="float-sub-menu-arrow"
                style={{
                    top: pageFloatSubMenuArrowTop,
                    bottom: pageFloatSubMenuArrowBottom
                }}
            />
            <div
                className="float-sub-menu-line"
                style={{
                    top: pageFloatSubMenuLineTop,
                    bottom: pageFloatSubMenuLineBottom
                }}
            />
            <ul className="float-sub-menu">
                {pageFloatSubMenu &&
                    pageFloatSubMenu.children &&
                    pageFloatSubMenu.children.map(
                        (menu: { path: string; exact: boolean }, i: number) => (
                            <Route
                                path={menu.path}
                                exact={menu.exact}
                                key={i}
                                children={({ match }) => (
                                    <FloatSubMenuList
                                        data={menu}
                                        key={i}
                                        expand={(e: any) => handleExpand(e, i, match)}
                                        active={i === active}
                                        clicked={clicked}
                                    />
                                )}
                            />
                        )
                    )}
            </ul>
        </div>
    )
}

export default FloatSubMenu
