import React, { useMemo } from 'react'
import useDataProvider from '@core/hooks/use-data-provider'
import TrikoAvailabilitySchema, { DayConfigType } from '@schemas/trikoAvailability.schema'
import { Table } from 'reactstrap'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'
import classnames from 'classnames'

const AvailabilityRender: React.FC = () => {
    const t = useTranslate('days')
    const { providedData } = useDataProvider<{ attributes: TrikoAvailabilitySchema }>()

    const availabilityConfig = providedData?.attributes
    const availabilityToPrint: (DayConfigType & { dayName: string })[] = useMemo(() => {
        if (!providedData || !providedData?.attributes) {
            return []
        }
        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        return days.reduce((outputConfig, currentItem) => {
            outputConfig.push({ ...availabilityConfig[currentItem], dayName: currentItem })
            return outputConfig
        }, [] as (DayConfigType & { dayName: string })[])
    }, [availabilityConfig])
    return (
        <Table bordered striped>
            <tbody>
                {availabilityToPrint.map((item, key) => (
                    <tr key={`item-row-${key}`}>
                        <td className={classnames({ 'text-gray-400': !item.active })}>
                            {t(item.dayName as DictionaryKeyType)}
                        </td>
                        <td>
                            {item.active === false ? (
                                <span className="text-gray-400">I'm not available</span>
                            ) : (
                                `${item.from} - ${item.to}`
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default AvailabilityRender
