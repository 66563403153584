import React, { useState } from 'react'
import { ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { Button, Icon } from '@alejandro.devop/bt-components'
import ListLoader from '@core/loaders/list-loader'
import { useGet, usePatch } from '@alejandro.devop/react-json-api-client'
import { workflowTypes } from '@constants/workflow-types'
import { TextWrapper, ItemWrapper } from './styled'
import workflowLocales from '@locales/es/workflows.json'
import LoadingCurtain from '@core/loaders/loader-curtain/LoadingCuirtain'

const ChangeTrikoStatus: React.FC<{
    trikoId: any
    currentWorkflow: any
    onSaved: () => void
    onCancel: () => void
}> = ({ trikoId, currentWorkflow, onSaved, onCancel }) => {
    const [currentState, setCurrentState] = useState(currentWorkflow?.id)
    const [sendPatch, updating] = usePatch<AvailableEndpointsType>('trikos.updateWorkflow', {
        replacements: {
            id: trikoId
        }
    })
    const [data, { loading }] = useGet<AvailableEndpointsType>('general.workflows', {
        filters: {
            workflow_transition_type_id: workflowTypes.TRIKO
        }
    })
    const handleSelectItem = (item: any) => {
        setCurrentState(item)
    }

    const saveTrikoWorkflow = async () => {
        try {
            await sendPatch<PayloadType<any>>({
                data: {
                    type: 'trikos',
                    id: trikoId,
                    attributes: {
                        workflow_transition_id: currentState
                    }
                }
            })
            onSaved()
        } catch (err) {
            console.log('Error: ', err)
        }
    }

    return (
        <Modal toggle={() => onCancel()} isOpen size="md">
            <ModalHeader>Change workflow</ModalHeader>

            <ModalBody>
                {loading && <ListLoader />}
                {!loading && (
                    <ListGroup flush>
                        {data &&
                            Array.isArray(data) &&
                            data.map((item: any, key) => (
                                <ListGroupItem
                                    active={currentState === parseInt(item.id, 10)}
                                    key={`key-${key}`}
                                    action={true}
                                    tag="div"
                                    onClick={() => handleSelectItem(parseInt(item.id, 10))}
                                    className="bg-green-200"
                                >
                                    <ItemWrapper>
                                        <TextWrapper>
                                            {`${workflowLocales[item.attributes.workflow]} - ${
                                                item.id
                                            }`}
                                        </TextWrapper>
                                        {currentState === parseInt(item.id, 10) && (
                                            <Icon type="circle" className="text-white" />
                                        )}
                                    </ItemWrapper>
                                </ListGroupItem>
                            ))}
                    </ListGroup>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => saveTrikoWorkflow()}
                    variant="success"
                    disabled={parseInt(currentWorkflow?.id, 10) === currentState}
                >
                    Aplicar
                </Button>
                <Button onClick={() => onCancel()} variant="info">
                    Cerrar
                </Button>
            </ModalFooter>
            {updating && <LoadingCurtain />}
        </Modal>
    )
}

export default ChangeTrikoStatus
