import { GridConfig } from '@alejandro.devop/bootstrap-smart-grid'
import WorkflowMapIds from '@constants/workflows-map'
import endpointDefinitions from '@definitions/endpoints.def.json'

const config: GridConfig<keyof typeof endpointDefinitions> = {
    loadingLabel: 'Loading services',
    debug: false,
    id: 'services-grid',
    url: 'services.list',
    defaultFilters: {
        // region: 1
    },
    displayFiltersFormatter: (filters: { label: string; value: any }[]) => {
        return filters.map((filter) => {
            if (filter.label === 'workflow_transition') {
                filter.value = WorkflowMapIds[filter.value]
            }
            return filter
        })
    },
    fields: {
        id: { label: 'ID', value: ({ id }: { id: number }) => `${id}` },
        name: {
            label: 'name',
            value: ({ attributes }) => attributes.name['es'],
            filter: true,
            sort: true
        },
        type: {
            filter: true,
            label: 'serviceType',
            value: ({ attributes }) => {
                const { servicetype } = attributes
                return servicetype.name['es']
            },
            filterOptions: {
                type: 'select',
                preProcessFilterValue: (val) => `[${val}]`,
                selectOptions: {
                    advanced: true,
                    ajax: true,
                    url: 'servicesTypes.list',
                    columnsMapping: {
                        label: 'name',
                        value: 'id',
                        labelProcessor: (val) => {
                            return val['es']
                        }
                    }
                }
            }
        }
    },
    actions: [{ icon: 'eye', id: 'view', label: 'view' }],
    headers: {
        id: { label: 'ID', value: ({ id }: { id: number }) => `${id}` }
    }
}

export default config
