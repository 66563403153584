import axios from 'axios'
type FetcherConfigType = {
    server: string
    endpoints: any
}

class JarvisFetch {
    private readonly server: string
    private readonly endpoints: any
    constructor(config: FetcherConfigType) {
        const { server, endpoints } = config
        this.server = server
        this.endpoints = endpoints
    }

    public resolveUrl(path: string, params?: { [k: string]: any }): string {
        const parts = path.split('.')
        let composedUrl = path
        if (parts.length > 1) {
            composedUrl = parts.reduce((newVal: any, currentPart: string) => {
                if (newVal[currentPart]) {
                    newVal = newVal[currentPart]
                }
                return newVal
            }, this.endpoints)
        }

        if (typeof composedUrl !== 'string') {
            return path
        }
        if (params) {
            Object.keys(params).forEach((currentItem) => {
                const regExpText = `:${currentItem}:`
                composedUrl = composedUrl.replace(new RegExp(regExpText, 'g'), params[currentItem])
            })
        }
        return composedUrl
    }

    public async fetchData(path: JarvisEndpointType, params?: { [k: string]: any }): Promise<any> {
        try {
            const url = this.resolveUrl(path, params)
            const response = await axios.get(`${this.server}${url}`)
            return response.data
        } catch {
            return []
        }
    }
}

export default JarvisFetch
