import styled from 'styled-components'

export const ImagePreviewImage = styled.img`
    &.isViewer {
        width: 200px;
        align-self: center;
    }
`

export const ServiceImage = styled.img`
    width: 30px;
    height: 30px;
`
export const ServiceWrapper = styled.div`
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 10px;
    &.noServices {
        background-color: #fff59d;
    }
    &.rejected {
        background-color: #ef9a9a8f;
    }
    &.approved {
        background-color: #a5d6a78f;
    }
`
export const ServiceContentWrapper = styled.div`
    flex: ${(props) => (props.className === 'actions' ? 1 : 2)};
    padding: 0px 10px;
`

export const DropZone = styled.div`
    background-color: #c8e6c9;
    width: 100%;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    border: 4px dashed #a5d6a7;
    border-radius: 8px;
    & .icon {
        font-size: 60px;
        margin-bottom: 10px;
        color: #43a047;
    }
    & p {
        color: #43a047;
    }
`

export const DropZoneInput = styled.input`
    background-color: blue;
`
