import React, { useState, useEffect } from 'react'
import { RequiredDocType } from '../../types'
import { Alert, Badge, Table } from 'reactstrap'
import { Button, IconButton } from '@alejandro.devop/bt-components'
import { useTranslate } from '@core/hooks'

interface DocumentBaseProps {
    docItem: RequiredDocType
}
let timer: any = null
const KnowledgeTestRender: React.FC<DocumentBaseProps> = ({ docItem }) => {
    const { attributes } = docItem
    const { triko } = attributes
    const { pi } = triko
    const [visible, setVisible] = useState<boolean>(false)
    const t = useTranslate()

    const copyToClipboard = async (text?: string) => {
        try {
            if (navigator.clipboard && text) {
                await navigator.clipboard.writeText(text)
                setVisible(true)
                timer = setTimeout(() => {
                    setVisible(false)
                }, 2000)
            }
        } catch {
            // Handle error when copy
        }
    }
    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])
    return (
        <>
            <Alert color="warning">{pi.first_name} ha indicado que diligenció el formulario</Alert>
            <Table>
                <tr>
                    <td>Documento identidad: </td>
                    <td>
                        {pi.id_number}
                        {!visible && (
                            <IconButton
                                onClick={() => copyToClipboard(pi.id_number?.toString())}
                                icon="clipboard"
                            />
                        )}
                        {visible && (
                            <Badge className="ml-8" color="success">
                                {t('general.textCopied')}
                            </Badge>
                        )}
                    </td>
                </tr>
            </Table>
            <div className="mb-12">
                <Button variant="info">view google form</Button>
            </div>
        </>
    )
}

export default KnowledgeTestRender
