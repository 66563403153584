import * as React from 'react'
import { Link, Route } from 'react-router-dom'
import useAppSettings from '@core/app-context/hooks'
const { useState } = React

type MenuType = {
    icon?: string
    img?: string
    label?: string
    badge?: string
    path?: string | any
    exact?: boolean
    title?: string
    children?: MenuType[]
}

interface Props {
    active?: boolean | number
    clicked?: boolean | number
    expand?: (e: any) => void
    data: MenuType
}

const FloatSubMenuList: React.FC<Props> = ({ active, clicked, expand, data = {} }) => {
    const { children, icon, img, label, badge, path, exact, title } = data
    const { pageSidebarMinified, handleFloatSubMenuClick } = useAppSettings()
    const [states, setStates] = useState<{ [key: string]: number | boolean }>({
        active: -1,
        clicked: -1
    })
    const { active: isActive, clicked: isClicked } = states

    const handleExpand = (e: any, i: number) => {
        e.preventDefault()
        setStates({
            active: isActive === i ? -1 : i,
            clicked: 1
        })
        setTimeout(() => {
            handleFloatSubMenuClick()
        }, 0)
    }

    const getContent = () => {
        return (
            <>
                {img && (
                    <span className="icon-img">
                        <img src={img} alt="" />
                    </span>
                )}
                {icon && <i className={icon} />}
                {title && (
                    <span>
                        {title} {label && <span className="label label-theme m-l-5">{label}</span>}
                    </span>
                )}
                {badge && <span className="badge pull-right">{badge}</span>}
                {children && !badge && <b className="caret" />}
            </>
        )
    }

    return (
        <>
            <Route
                path={path}
                exact={exact}
                children={({ match }) => (
                    <li
                        className={
                            (match ? 'active ' : '') +
                            (active || (clicked === -1 && match) ? 'expand ' : 'closed ') +
                            (children ? 'has-sub ' : '')
                        }
                    >
                        {children ? (
                            <Link to={path} onClick={expand}>
                                {getContent()}
                            </Link>
                        ) : (
                            <Link to={path}>{getContent()}</Link>
                        )}
                        {children && (
                            <ul
                                className={
                                    'sub-menu ' +
                                    ((active || (clicked === -1 && match)) && !pageSidebarMinified
                                        ? 'd-block '
                                        : 'd-none')
                                }
                            >
                                {children &&
                                    children.map((submenu, i) => (
                                        <FloatSubMenuList
                                            data={submenu}
                                            key={i}
                                            expand={(e: any) => handleExpand(e, i)}
                                            active={i === isActive}
                                            clicked={isClicked}
                                        />
                                    ))}
                            </ul>
                        )}
                    </li>
                )}
            />
        </>
    )
}

export default FloatSubMenuList
