import { useMemo, useCallback } from 'react'
import menuConfig from '@config/navigation/menu'
import { useUrlResolver } from '@core/navigation/hooks'
import { menuType, menuOptionType } from './types'

/**
 * This hook allows to build the menu configuration, returns the three that allows the template
 * to render the menu
 * @version 1.0.0
 * @author Alejandro <alejandro.devop@gmail.com>
 */
const useMenuConfig = (): menuOptionType[] => {
    const resolveUrl = useUrlResolver()
    const resolveMenu = useCallback(
        (keys: string[], config, parent?: string) =>
            keys.reduce((resolvedMenu: menuType, currentKey) => {
                const currentItem = config[currentKey]
                let menuToAdd: menuOptionType | null = null

                if (typeof currentItem === 'object' && currentItem.path) {
                    const { icon, path } = currentItem
                    menuToAdd = { icon, path: resolveUrl(path), title: currentKey }
                } else if (typeof currentItem === 'object' && !currentItem.path) {
                    const { icon, ...otherKeys } = currentItem
                    const fakePath = `/${parent ? parent : ''}${currentKey
                        .toLowerCase()
                        .replace(/ /g, '-')}`
                    menuToAdd = {
                        icon,
                        title: currentKey,
                        path: fakePath,
                        children: resolveMenu(Object.keys(otherKeys), otherKeys, fakePath)
                    }
                } else if (typeof currentItem === 'string') {
                    const parts = currentKey.split('|icon|')
                    const title = parts[0]
                    const icon = parts.length > 0 ? parts[1] : null
                    menuToAdd = { title, path: resolveUrl(currentItem), icon }
                }

                if (menuToAdd !== null) {
                    resolvedMenu.push(menuToAdd)
                }
                return resolvedMenu
            }, []),
        [resolveUrl]
    )

    return useMemo(() => {
        return resolveMenu(Object.keys(menuConfig), menuConfig)
    }, [resolveMenu])
}

export default useMenuConfig
