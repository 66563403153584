import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ServiceRequestDetailType } from './types'
import { useGet } from '@alejandro.devop/react-json-api-client'
import { ImageRender } from './styled'

const RequestedService: React.FC<{ requestId: any }> = ({ requestId }) => {
    const [data, { loading }] = useGet<AvailableEndpointsType, ServiceRequestDetailType>(
        'serviceRequests.detailRows',
        {
            replacements: {
                request_id: requestId
            }
        }
    )
    if (loading || !data) {
        return null
    }
    const { attributes } = data
    const { service } = attributes
    const { icon, name } = service
    return (
        <>
            <Card className="mb-4">
                <CardBody>
                    <h3>Servicio solicitado</h3>
                    <div className="flex flex-row">
                        <ImageRender src={icon} />
                        <div className="ml-12 pt-4">
                            <h4>{name.es}</h4>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default RequestedService
