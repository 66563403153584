/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react'
import useAppSettings from '@core/app-context/hooks'
import { BrowserRouter } from 'react-router-dom'
import endpoints from '@config/endpoints.json'
import ApiClientConfigurator from '@alejandro.devop/react-json-api-client'
import useSession from '@session/hooks'
// const { REACT_APP_SERVER_API } = process.env

interface Props {
    children: React.ReactNode
}

/**
 * This component was refactored from the original color admin theme https://seantheme.com/color-admin/admin/html/index_v3.html
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param children
 * @constructor
 */
const AppWrapper: React.FC<Props> = ({ children }) => {
    const {
        pageHeader,
        pageSidebarMinify,
        pageSidebar,
        pageRightSidebar,
        pageSidebarWide,
        pageSidebarLight,
        pageSidebarToggled,
        pageTopMenu,
        pageContentFullHeight,
        pageTwoSidebar,
        pageMobileRightSidebarToggled,
        pageRightSidebarCollapsed,
        hasScroll
    } = useAppSettings()
    const { store } = useSession()
    const { token, tokenType } = store
    return (
        <>
            {/* @ts-ignore */}
            <BrowserRouter>
                <div
                    className={
                        'fade page-sidebar-fixed show page-container ' +
                        (pageHeader ? 'page-header-fixed ' : '') +
                        (pageSidebar ? '' : 'page-without-sidebar ') +
                        (pageRightSidebar ? 'page-with-right-sidebar ' : '') +
                        (pageSidebarWide ? 'page-with-wide-sidebar ' : '') +
                        (pageSidebarLight ? 'page-with-light-sidebar ' : '') +
                        (pageSidebarMinify ? 'page-sidebar-minified ' : '') +
                        (pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
                        (pageTopMenu ? 'page-with-top-menu ' : '') +
                        (pageContentFullHeight ? 'page-content-full-height ' : '') +
                        (pageTwoSidebar ? 'page-with-two-sidebar ' : '') +
                        (pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : '') +
                        (pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '') +
                        (hasScroll ? 'has-scroll ' : '')
                    }
                >
                    <ApiClientConfigurator
                        config={{
                            dataProcessor: (data: any) => {
                                if (data.data && Array.isArray(data.data)) {
                                    return data.data
                                } else if (data.data && !Array.isArray(data.data)) {
                                    return { ...data.data, includedObjects: data.included }
                                } else {
                                    return data
                                }
                            },
                            headers: {
                                'Content-Type': 'application/vnd.api+json'
                            },
                            server: 'https://api.backoffice.dev.triko.co',
                            endpoints: endpoints,
                            auth: Boolean(token) ? `${tokenType} ${token}` : undefined
                        }}
                    >
                        {children}
                    </ApiClientConfigurator>
                </div>
            </BrowserRouter>
        </>
    )
}

export default AppWrapper
