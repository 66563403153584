const menuConfig: MenuConfigType = {
    Home: {
        icon: 'fa fa-th',
        path: 'home'
    },
    Trikos: {
        icon: 'fa fa-users',
        RegisteredTrikos: {
            icon: 'fa fa-user',
            path: 'trikos.registered'
        }
    },
    Clients: {
        icon: 'fa fa-solid fa-user-plus',
        RegisteredClients: {
            icon: 'fa fa-user-check',
            path: 'clientsManagePage'
        }
    },
    RequiredDocuments: {
        icon: 'fa fa-solid fa-file-alt',
        ManageDocuments: {
            icon: 'fa fa-file',
            path: 'requiredDocumentsPage'
        }
    },
    ServiceRequests: {
        icon: 'fa fa-list',
        ManageRequests: {
            icon: 'fa fa-list-alt',
            path: 'requestServicesManagePage'
        }
    },
    Accounting: {
        icon: 'fa fa-comments-dollar',
        Orders: {
            icon: 'fa fa-file-invoice-dollar'
        }
    },
    settings: {
        icon: 'fa fa-cogs',
        Services: {
            icon: 'fa fa-boxes',
            path: 'settings.services'
        },
        ServicesTypes: {
            icon: 'fa fa-boxes',
            path: 'settings.servicesTypes'
        },
        LanguagesAndTexts: {
            icon: 'fa fa-language',
            path: 'settings.languages'
        },
        Genders: {
            icon: 'fa fa-venus-mars',
            path: 'settings.configPanel.genders'
        },
        Countries: {
            icon: 'fa fa-globe-americas',
            path: 'settings.configPanel.countries'
        },
        Departments: {
            icon: 'fa fa-globe-americas',
            path: 'settings.configPanel.departments'
        },
        Cities: {
            icon: 'fa fa-globe-americas',
            path: 'settings.configPanel.cities'
        },
        IdentificationTypes: {
            icon: 'fa fa-id-card',
            path: 'settings.configPanel.idTypes'
        },
        BuildingTypes: {
            icon: 'fa fa-building',
            path: 'settings.configPanel.buildingTypes'
        },
        FamilyMembers: {
            icon: 'fa fa-house-user',
            path: 'settings.configPanel.familyMembers'
        },
        CivilStatuses: {
            icon: 'fa fa-people-arrows',
            path: 'settings.configPanel.civilStatuses'
        }
    }
}

export default menuConfig
