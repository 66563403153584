import * as React from 'react'
import { Link } from 'react-router-dom'
import { PanelBase as Panel, PanelBody, PanelHeader } from '@core/panel/PanelBase'

const RecoverPasswordPage: React.FC = () => {
    return (
        <div>
            <ol className="breadcrumb float-xl-right">
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Active Page</li>
            </ol>
            <h1 className="page-header">
                Recover password <small>Recover password</small>
            </h1>
            <Panel>
                <PanelHeader>Recover password</PanelHeader>
                <PanelBody>Recover password!</PanelBody>
            </Panel>
        </div>
    )
}

export default RecoverPasswordPage
