import { GridConfig } from '@alejandro.devop/bootstrap-smart-grid'
import WorkflowMapIds from '@constants/workflows-map'
import endpointDefinitions from '@definitions/endpoints.def.json'
import months from '@locales/es/months.json'
import workflowLocales from '@locales/es/workflows.json'
import moment from 'moment'
import serviceWorkflows from '@constants/service-workflows-map'

const config: GridConfig<keyof typeof endpointDefinitions> = {
    loadingLabel: 'Cargando solicitudes',
    debug: false,
    filtersVisible: true,
    id: 'services-grid',
    url: 'serviceRequests.list',
    columnVisibility: {},
    defaultFilters: {},
    displayFiltersFormatter: (filters: { label: string; value: any }[]) => {
        return filters.map((filter) => {
            if (filter.label === 'workflow_transition') {
                filter.value = WorkflowMapIds[filter.value]
            }
            return filter
        })
    },
    fields: {
        id: {
            label: 'Code',
            value: ({ id }: { id: number }) => `RQS${id.toString().padStart(6, '0')}`
        },
        application_date: {
            label: 'Day',
            filter: true,
            value: ({ attributes }) => {
                const date = moment(attributes.application_date, 'YYYY-MM-DD HH:mm:ss')
                const month = date.format('MMM').toLowerCase()
                return `${months[month]} ${date.format('DD / YYYY')}`
            },
            filterOptions: {
                type: 'date',
                label: 'Fecha de aplicación',
                preProcessFilterValue: (value) => {
                    const date = moment(value, 'YYYY/MM/DD')
                    return date.format('DD-MM-YYYY')
                }
            }
        },
        application_hour: {
            label: 'Time',
            value: ({ attributes }) => {
                const date = moment(attributes.application_date, 'YYYY-MM-DD HH:mm:ss')
                return date.format('hh:mm a')
            }
        },
        clientId: {
            filter: true,
            label: 'c_id',
            filterOptions: {
                label: 'Cédula cliente',
                name: 'client_id_number'
            },
            value: ({ attributes }) => {
                const { id_number } = attributes.client
                return `CC ${id_number}`
            }
        },
        client: {
            label: 'Client',
            value: ({ attributes }) => {
                const { first_name, last_name } = attributes.client
                return `${first_name} ${last_name}`
            }
        },
        status: {
            filter: true,
            filterOptions: {
                label: 'Estado',
                type: 'select',
                name: 'workflow',
                selectOptions: {
                    advanced: true,
                    ajax: true,
                    url: 'general.workflows',
                    requestConfig: {
                        filters: {
                            workflow_transition_type_id: 4 // Workflows for request status
                        }
                    },
                    columnsMapping: {
                        label: 'workflow',
                        value: 'id',
                        labelProcessor: (d) => {
                            return workflowLocales[`service.${d}`]
                        }
                    }
                }
            },
            label: 'Status',
            value: ({ attributes }) => {
                return workflowLocales[serviceWorkflows[attributes.workflow_transition_id]]
            }
        }
    },
    actions: [{ icon: 'eye', id: 'view', label: 'view' }],
    headers: {
        id: { label: 'ID', value: ({ id }: { id: number }) => `${id}` },
        statusColor: { hide: true },
        triko: { label: 'Nombres triko' },
        client: { label: 'Nombres Client' },
        trikoId: { label: 'CC triko' },
        clientId: { label: 'CC cliente' },
        application_hour: { label: 'Hora' },
        application_date: { label: 'Día' },
        duration: { label: 'Horas' }
    }
}

export default config
