import React from 'react'

const TestingHallPage: React.FC = () => {
    return (
        <>
            {/* <AdvancedGrid id="grid-2" {...grid} /> */}
            {/* <HabitCategories /> */}
            {/*<CreateHabitCategory selectedItem={{ id: 7, name: 'New category', icon: 'accusoft' }} />*/}
        </>
    )
}

export default TestingHallPage
