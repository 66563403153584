import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

export const ImageIcon = styled.img`
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 100%;
`

export const ItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
`

export const TextLoader = styled(Skeleton)``

export const ImageLoader = styled(Skeleton)``

export const LoaderItemWrapper = styled.div``
