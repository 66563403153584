import styled from 'styled-components'
import SkeletonBase from 'react-loading-skeleton'

export const SkeletonRouded = styled(SkeletonBase)`
    width: 50px;
    height: 50px;
    border-radius: 100%;
`
export const SkeletonText = styled(SkeletonBase)`
    flex: 1;
    width: 100%;
    height: 30;
    margin-bottom: 4px;
`
