import React, { useState } from 'react'
import { ImagePreviewImage } from './styled'
import classnames from 'classnames'
import 'react-medium-image-zoom/dist/styles.css'
import Zoom from 'react-medium-image-zoom'
import { Button, Icon } from '@alejandro.devop/bt-components'

interface ImagePreviewProps {
    imageUrl?: string
    allowViewer?: boolean
    allowDownload?: boolean
    allowRotation?: boolean
    onRotate?: (rotation?: number) => void
    style?: React.CSSProperties
    initialRotation?: number
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
    imageUrl,
    allowViewer,
    allowDownload,
    onRotate,
    style,
    initialRotation
}) => {
    const [rotation, setRotation] = useState(initialRotation || 0)
    const changeRotation = () => {
        let newRotation = rotation + 90
        if (newRotation >= 360) {
            newRotation = 0
        }
        setRotation(newRotation)
        if (onRotate) {
            onRotate(newRotation)
        }
    }
    // Todo: Implement something when the image does not exists.
    if (!imageUrl) {
        return null
    }
    return (
        <div className="flex justify-center flex-col items-center">
            {!allowViewer && (
                <ImagePreviewImage
                    src={imageUrl}
                    className={classnames({ isViewer: allowViewer })}
                />
            )}
            {allowViewer && (
                <Zoom
                    transitionDuration={400}
                    zoomMargin={70}
                    overlayBgColorStart={'rgba(0,0,0,0.0)'}
                    overlayBgColorEnd={'rgba(0,0,0,0.7)'}
                >
                    <ImagePreviewImage
                        src={imageUrl}
                        className={classnames({ isViewer: allowViewer })}
                        style={{ transform: `rotate(${rotation}deg)`, ...style }}
                    />
                </Zoom>
            )}
            {allowDownload && (
                <div className="mt-16">
                    <a href={imageUrl} download target="_blank" className="mr-2">
                        <Button variant="info">download</Button>
                    </a>
                    <Button onClick={changeRotation}>
                        <span className="mr-2">Rotate right </span>
                        <Icon type="sync" />
                    </Button>
                </div>
            )}
        </div>
    )
}

export default ImagePreview
