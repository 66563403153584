import React from 'react'
import { Table } from 'reactstrap'
import moment from 'moment'
import { UserInfoProps } from './types'
import { useTranslate } from '@core/hooks'
import useCopyRender from '@core/hooks/use-copy-render'

const UserInfo: React.FC<UserInfoProps> = ({
    email,
    userID,
    clientID,
    phoneNumber,
    createdAt,
    verifyCode
}) => {
    const t = useTranslate()
    const { renderCopyOption } = useCopyRender({
        userId: false,
        trikoId: false,
        verifyCode: false
    })

    return (
        <>
            <div className="w-full">
                <div className="mt-4">
                    <h4 className="text-center">Account information</h4>
                    <Table>
                        <tbody>
                            <tr>
                                <td>{t('detail.userId')}</td>
                                <td>
                                    {userID} {renderCopyOption('userId', userID?.toString())}
                                </td>
                            </tr>
                            <tr>
                                <td>{t('detail.clientId')}</td>
                                <td>
                                    {clientID} {renderCopyOption('clietnID', clientID?.toString())}
                                </td>
                            </tr>
                            <tr>
                                <td>{t('detail.userEmail')}</td>
                                <td>{email}</td>
                            </tr>
                            <tr>
                                <td>{t('detail.userNumber')}</td>
                                <td>{phoneNumber}</td>
                            </tr>
                            <tr>
                                <td>{t('detail.userRegisterDate')}</td>
                                <td>
                                    {moment(createdAt, 'YYYY-MM-DD HH:mm:ss').format(
                                        'YYYY/MM/DD hh:mm:ss a'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{t('detail.verifyCode')}</td>
                                <td>
                                    {verifyCode}
                                    {renderCopyOption('verifyCode', verifyCode)}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default UserInfo
