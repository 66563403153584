import React from 'react'
import { Modal, ModalBody, Container, Row, Col, ModalHeader, ModalFooter } from 'reactstrap'
import { Button } from '@alejandro.devop/bt-components'
import ClientInfo from './ClientInfo'
import TrikoInfo from './TrikoInfo'
import { useGet } from '@alejandro.devop/react-json-api-client'
import Loader from './Loader'
import { RequestType } from './types'
import ServiceInfo from 'components/request-detail/ServiceInfo'
import RequestedService from 'components/request-detail/RequestedService'
import Timeline from './timeline'

interface RequestDetailProps {
    requestId: any
    disableClient?: boolean
    disableTriko?: boolean
    onClose: () => void
}

const RequestDetail: React.FC<RequestDetailProps> = ({
    requestId,
    onClose,
    disableClient,
    disableTriko
}) => {
    const [data, { loading }] = useGet<AvailableEndpointsType, RequestType>(
        'serviceRequests.detail',
        {
            replacements: {
                request_id: requestId
            }
        }
    )

    const { attributes } = data || {}
    const { client, triko: trikos } = attributes || {}
    const [triko] = trikos || []
    return (
        <>
            <Modal toggle={() => onClose()} isOpen size="xl">
                <ModalHeader>View request detail</ModalHeader>
                <ModalBody>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Container>
                            <Row>
                                <Col xs="12" md="4">
                                    {data && <ServiceInfo info={data} />}
                                </Col>
                                <Col xs="12" md="4">
                                    <RequestedService requestId={requestId} />
                                    {!disableClient && <ClientInfo client={client} />}
                                    {!disableTriko && <TrikoInfo triko={triko} />}
                                </Col>
                                <Col xs="12" md="4">
                                    <Timeline history={data?.attributes.workflowHistory} />
                                </Col>
                            </Row>
                        </Container>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => onClose()}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default RequestDetail
