import { GridConfig } from '@alejandro.devop/bootstrap-smart-grid'
import WorkflowMapIds from '@constants/workflows-map'
import endpointDefinitions from '@definitions/endpoints.def.json'
import { requiredDocWorkflows } from '../../../@constants/required-doc-workflows.map'
import workflowLocales from '@locales/es/workflows.json'
import { workflowTypes } from '@constants/workflow-types'

const config: GridConfig<keyof typeof endpointDefinitions> = {
    loadingLabel: 'Cargando solicitudes',
    debug: false,
    filtersVisible: true,
    id: 'documents-grid',
    url: 'trikos.requiredDocumentsList',
    columnVisibility: {
        address: false
    },
    defaultFilters: {},
    displayFiltersFormatter: (filters: { label: string; value: any }[]) => {
        return filters.map((filter) => {
            if (filter.label === 'workflow_transition') {
                filter.value = WorkflowMapIds[filter.value]
            }
            return filter
        })
    },
    fields: {
        id: {
            label: 'Code',
            value: ({ id }: { id: number }) => `DOC${id.toString().padStart(6, '0')}`
        },
        requirement: {
            label: 'Documento',
            filter: true,
            filterOptions: {
                label: 'Tipo de documento',
                type: 'select',
                name: 'type_id',
                selectOptions: {
                    advanced: true,
                    ajax: true,
                    url: 'general.requirementTypes',
                    columnsMapping: {
                        label: 'name',
                        value: 'id',
                        labelProcessor: (e: any) => {
                            return e.es
                        }
                    }
                }
            },
            value: ({ attributes }) => {
                const { requirement } = attributes || {}
                const { name } = requirement || {}
                return name?.es
            }
        },
        phonenumber: {
            filter: false,
            label: 'Teléfono',
            value: ({ attributes }) => {
                const { triko } = attributes || {}
                const { user } = triko || {}
                return user?.phonenumber
            }
        },
        idNumber: {
            filter: true,
            filterOptions: {
                name: 'id_number',
                label: 'Identificación'
            },
            label: 'No. Identificación',
            value: ({ attributes }) => {
                const { triko } = attributes || {}
                const { pi } = triko || {}
                return pi.id_number
            }
        },
        fullName: {
            label: 'Nombre completo',
            filter: true,
            filterOptions: {
                name: 'fullname',
                label: 'Nombre completo'
            },
            value: ({ attributes }) => {
                const { triko } = attributes || {}
                const { pi } = triko || {}
                const { first_name: firstName, last_name: lastName } = pi || {}
                return `${firstName} ${lastName}`
            }
        },
        status: {
            label: 'Estado',
            filter: true,
            filterOptions: {
                name: 'workflow',
                label: 'Estado',
                type: 'select',
                selectOptions: {
                    ajax: true,
                    advanced: true,
                    url: 'general.workflows',
                    requestConfig: {
                        filters: {
                            workflow_transition_type_id: workflowTypes.REQUIRED_DOCUMENTATION // Workflows for request status
                        }
                    },
                    columnsMapping: {
                        label: 'workflow',
                        value: 'id',
                        labelProcessor: (d) => {
                            return workflowLocales[`document.${d}`] || d
                        }
                    }
                }
            },
            value: ({ attributes }) => {
                const { workflow_transition_id: workflow } = attributes
                return requiredDocWorkflows[workflow]
            }
        }
    },
    actions: [{ icon: 'eye', id: 'view', label: 'view' }],
    headers: {
        id: { label: 'ID', value: ({ id }: { id: number }) => `${id}` },
        requirement: { label: 'Tipo de documento' },
        phonenumber: { label: 'Télefono' },
        idNumber: { label: 'No. Identificación' },
        fullName: { label: 'Nombre completo' },
        status: { label: 'Estado' }
    }
}

export default config
