import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import ChartBase from '@core/components/charts/chart-base'
import { useClientsPerCity } from '@services/jarvis/hooks'

const ClientsPerCity: React.FC = () => {
    const { data, loading } = useClientsPerCity()

    const dataSetBuilt = useMemo(() => {
        if (!data) return []

        return data.reduce(
            (currentSet, currentItem: any) => {
                currentSet.labels.push(currentItem.city)
                currentSet.data.push(currentItem.total)
                return currentSet
            },
            { labels: [], data: [] } as { labels: any[]; data: any[] }
        )
    }, [data])
    return (
        <ChartBase title="Top 10: Clientes por ciudad" loading={loading}>
            <div style={{ height: 260 }}>
                <Bar
                    datasetIdKey="id"
                    options={{
                        plugins: { legend: { position: 'bottom', display: false } },
                        maintainAspectRatio: false
                    }}
                    data={{
                        labels: dataSetBuilt.labels,
                        datasets: [
                            {
                                label: 'Clients',
                                data: dataSetBuilt.data,
                                backgroundColor: '#039be58f'
                            }
                        ]
                    }}
                />
            </div>
        </ChartBase>
    )
}

export default ClientsPerCity
