import React, { useState, useEffect } from 'react'
import { useGet } from '@alejandro.devop/react-json-api-client'
import { RequiredDocType } from './types'
import DocumentCard from './DocumentCard'
import DocumentsLoader from './DocumentsLoader'
import { Alert, Container, Row, Col } from 'reactstrap'
import DocumentDetail from './document-detail'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ColumnWrapper } from '././styled'
import { useDetailContext } from '../../hooks'
import { useTranslate } from '@core/hooks'
import { Button } from '@alejandro.devop/bt-components'

let timer: any = null

const RequiredDocs: React.FC = () => {
    const [selectedDoc, setSelectedDocument] = useState<any>()
    const [visibleUpdatedMessage, setUpdatedMessageVisible] = useState<boolean>(false)
    const { trikoId } = useDetailContext()
    const t = useTranslate()
    const [records, { loading, refresh }] = useGet<AvailableEndpointsType, RequiredDocType[]>(
        'trikos.documents',
        {
            filters: {
                triko_id: trikoId
            }
        }
    )

    const handleUpdateDocument = (d: RequiredDocType) => {
        setSelectedDocument(d)
    }
    const handleDocumentUpdated = () => {
        setSelectedDocument(null)
        setUpdatedMessageVisible(true)
        timer = setTimeout(() => {
            setUpdatedMessageVisible(false)
        }, 3000)
        refresh()
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <Container>
            <Row>
                <Col xs="12" lg="6">
                    <ColumnWrapper>
                        <div className="mb-4 flex flex-row justify-end">
                            <Button variant="info" onClick={() => refresh()}>
                                {t('detail.reloadDocuments')}
                            </Button>
                        </div>
                        <PerfectScrollbar>
                            {loading && <DocumentsLoader />}
                            {!loading &&
                                Array.isArray(records) &&
                                records?.map((item, key) => (
                                    <DocumentCard
                                        documentItem={item}
                                        key={`document-${key}`}
                                        onViewDocument={() => setSelectedDocument(item)}
                                    />
                                ))}
                        </PerfectScrollbar>
                    </ColumnWrapper>
                </Col>
                <Col xs="12" lg="6">
                    <div className="w-full">
                        {visibleUpdatedMessage && (
                            <Alert color="success">{t('detail.documentUpdatedSuccess')}</Alert>
                        )}
                        {!visibleUpdatedMessage && !Boolean(selectedDoc) && (
                            <Alert color="info">
                                <h4 className="alert-heading">
                                    {t('detail.requiredDocumentation')}
                                </h4>
                                <p>{t('detail.requiredDocumentationInfo')}</p>
                                <hr />
                                <p className="mb-0">{t('detail.requiredDocumentationInfo2')}</p>
                            </Alert>
                        )}
                        {Boolean(selectedDoc) && (
                            <DocumentDetail
                                onDocumentUpdated={handleDocumentUpdated}
                                updateDocument={handleUpdateDocument}
                                docItem={selectedDoc as any}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default RequiredDocs
