import React from 'react'
import { RequiredDocType } from '../../types'
import ImagePreview from './ImagePreview'
import { Alert } from 'reactstrap'

const ProfilePhotoRender: React.FC<{ docItem: RequiredDocType }> = ({ docItem }) => {
    const [frontFile] = (docItem.attributes.url_download_file || '')?.split(',')
    return (
        <div>
            <Alert>Click over the image to see it zoomed</Alert>
            <div className="px-4">
                <h5 className="text-center mb-4">Front image</h5>
                <ImagePreview imageUrl={frontFile} allowViewer allowDownload />
            </div>
            <hr />
        </div>
    )
}

export default ProfilePhotoRender
