import { useState, useEffect } from 'react'
import JarviceInstance from './JarvisService'

export const useTrikosPerState = (): { loading: boolean; data: any[] } => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>([])
    const fetchTrikos = async () => {
        try {
            setLoading(true)
            const response = await JarviceInstance.getTrikosPerState()
            setData(response)
            setLoading(false)
        } catch {}
    }
    useEffect(() => {
        fetchTrikos()
    }, [])
    return { loading, data }
}

export const useTrikosPerCity = (): { loading: boolean; data: any[] } => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>([])
    const fetchTrikos = async () => {
        try {
            setLoading(true)
            const response = await JarviceInstance.getTrikosPerCity()
            setData(response)
            setLoading(false)
        } catch {}
    }
    useEffect(() => {
        fetchTrikos()
    }, [])
    return { loading, data }
}

export const useClientsPerCity = (): { loading: boolean; data: any[] } => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>([])
    const fetchTrikos = async () => {
        try {
            setLoading(true)
            const response = await JarviceInstance.getClientsPerCity()
            setData(response)
            setLoading(false)
        } catch {}
    }
    useEffect(() => {
        fetchTrikos()
    }, [])
    return { loading, data }
}

export const useRequestsPerDate = (options: {
    fromDate: string
    endDate: string
}): { loading: boolean; data: any[] } => {
    const { fromDate, endDate } = options
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>([])
    const fetchTrikos = async () => {
        try {
            setLoading(true)
            const response = await JarviceInstance.getRequestsPerDate(fromDate, endDate)
            setData(response)
            setLoading(false)
        } catch {}
    }
    useEffect(() => {
        fetchTrikos()
    }, [])
    return { loading, data }
}
