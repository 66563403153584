import React from 'react'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

const ListLoader: React.FC = () => {
    return (
        <div>
            {_.times(10, (index) => (
                <Skeleton key={index} />
            ))}
        </div>
    )
}

export default ListLoader
