import React from 'react'
import styled from 'styled-components'

export const Badge = styled.span`
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-right: 4px;
`

export const getBadge = (color: string): any => {
    return <Badge style={{ backgroundColor: color }} />
}
