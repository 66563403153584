import * as React from 'react'
import { contextValueType, Props, appContextState } from './types'

export const AppContext = React.createContext({})
export const ContextProvider = AppContext.Provider

const { useState, useEffect, useCallback } = React

/**
 * Component to provide configurations and functions to application themes.
 * This was refactored from the original color admin theme https://seantheme.com/color-admin/admin/html/index_v3.html
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param children
 * @constructor
 */
const AppContextProvider: React.FC<Props> = ({ children }) => {
    const [values = {}, setContext] = useState<appContextState>({
        pageHeader: true,
        pageSidebar: true,
        pageContent: true,
        pageFooter: true,
        pageRightSidebarToggled: true
    })

    let floatSubMenuRemove: NodeJS.Timer
    let floatSubMenuCalculate: NodeJS.Timer
    const floatSubMenuRemoveTime = 250

    const handleChangeContext = useCallback(
        (keys: { [key: string]: contextValueType } = {}) => {
            setContext({
                ...values,
                ...keys
            })
        },
        [setContext, values]
    )

    const handleFloatSubMenuOnMouseOver = (): void => {
        clearTimeout(floatSubMenuRemove)
        clearTimeout(floatSubMenuCalculate)
    }

    const handleFloatSubMenuOnMouseOut = (): void => {
        floatSubMenuRemove = setTimeout(() => {
            handleChangeContext({ pageFloatSubMenuActive: false })
        }, floatSubMenuRemoveTime)
    }

    const {
        pageSidebarMinify,
        pageFloatSubMenuOffset,
        pageMobileTopMenu,
        pageRightSidebarCollapsed
    } = values

    const handleSidebarOnMouseOver = (e: React.MouseEvent, menu: { children: unknown }) => {
        if (pageSidebarMinify) {
            const sidebarEl: HTMLElement | null = document.getElementById('sidebar')
            if (menu.children && sidebarEl) {
                const left = sidebarEl.offsetWidth + sidebarEl.offsetLeft + 'px'
                clearTimeout(floatSubMenuRemove)
                clearTimeout(floatSubMenuCalculate)
                handleChangeContext({
                    pageFloatSubMenu: menu,
                    pageFloatSubMenuActive: true,
                    pageFloatSubMenuLeft: left
                })
                type targetType = HTMLElement | HTMLInputElement | null
                const Target: targetType = e.currentTarget as HTMLInputElement
                const offset =
                    Target && Target.offsetParent
                        ? Target.offsetParent.getBoundingClientRect()
                        : { top: 0 }

                floatSubMenuCalculate = setTimeout(() => {
                    const targetTop = offset.top
                    const windowHeight = window.innerHeight
                    const domEl: targetType = document.querySelector('.float-sub-menu-container')
                    const targetHeight = domEl ? domEl.offsetHeight : 0
                    let top = '0px'
                    let bottom = '0px'
                    let arrowTop = '0px'
                    let arrowBottom = '0px'
                    let lineTop = '0px'
                    let lineBottom = '0px'

                    if (windowHeight - targetTop > targetHeight) {
                        top = offset.top + 'px'
                        bottom = 'auto'
                        arrowTop = '20px'
                        arrowBottom = 'auto'
                        lineTop = '20px'
                        lineBottom = 'auto'
                    } else {
                        const aBottom = windowHeight - targetTop - 21
                        top = 'auto'
                        bottom = '0'
                        arrowTop = 'auto'
                        arrowBottom = aBottom + 'px'
                        lineTop = '20px'
                        lineBottom = aBottom + 'px'
                    }
                    handleChangeContext({
                        pageFloatSubMenuTop: top,
                        pageFloatSubMenuBottom: bottom,
                        pageFloatSubMenuLineTop: lineTop,
                        pageFloatSubMenuLineBottom: lineBottom,
                        pageFloatSubMenuArrowTop: arrowTop,
                        pageFloatSubMenuArrowBottom: arrowBottom,
                        pageFloatSubMenuOffset: offset
                    })
                }, 0)
            } else {
                floatSubMenuRemove = setTimeout(() => {
                    handleChangeContext({
                        pageFloatSubMenu: '',
                        pageFloatSubMenuActive: false
                    })
                }, floatSubMenuRemoveTime)
            }
        }
    }

    const handleSidebarOnMouseOut = () => {
        if (pageSidebarMinify) {
            floatSubMenuRemove = setTimeout(() => {
                handleChangeContext({
                    pageFloatSubMenuActive: false
                })
            }, floatSubMenuRemoveTime)
        }
    }

    const handleFloatSubMenuClick = () => {
        if (pageSidebarMinify) {
            const windowHeight = window.innerHeight
            const targetEl: HTMLElement | null = document.getElementById('float-sub-menu')
            const sideBarEl: HTMLElement | null = document.getElementById('sidebar')
            const targetHeight = targetEl ? targetEl.offsetHeight : 0
            const targetTop =
                pageFloatSubMenuOffset && pageFloatSubMenuOffset.top
                    ? pageFloatSubMenuOffset.top
                    : 0
            const top = windowHeight - targetTop > targetHeight ? targetTop : 'auto'
            const left =
                pageFloatSubMenuOffset.left + (sideBarEl ? sideBarEl.offsetWidth : 0) + 'px'
            const bottom = windowHeight - targetTop > targetHeight ? 'auto' : '0'
            const arrowTop = windowHeight - targetTop > targetHeight ? '20px' : 'auto'
            const arrowBottom =
                windowHeight - targetTop > targetHeight
                    ? 'auto'
                    : windowHeight - targetTop - 21 + 'px'
            const lineTop = windowHeight - targetTop > targetHeight ? '20px' : 'auto'
            const lineBottom =
                windowHeight - targetTop > targetHeight
                    ? 'auto'
                    : windowHeight - targetTop - 21 + 'px'

            handleChangeContext({
                pageFloatSubMenuTop: top,
                pageFloatSubMenuLeft: left,
                pageFloatSubMenuBottom: bottom,
                pageFloatSubMenuLineTop: lineTop,
                pageFloatSubMenuLineBottom: lineBottom,
                pageFloatSubMenuArrowTop: arrowTop,
                pageFloatSubMenuArrowBottom: arrowBottom
            })
        }
    }

    const handleSetPageHeader = (value: contextValueType) => {
        handleChangeContext({
            pageHeader: value
        })
    }

    const toggleRightSidebar = (e: Event | null) => {
        if (e) {
            e.preventDefault()
        }
        handleChangeContext({
            pageRightSidebarCollapsed: !pageRightSidebarCollapsed
        })
    }

    const handleSetPageContent = (value: contextValueType) => {
        handleChangeContext({
            pageContent: value
        })
    }

    const handleSetPageContentClass = (value: contextValueType) => {
        handleChangeContext({
            pageContentClass: value
        })
    }

    const handleSetPageContentFullHeight = (value: contextValueType) => {
        handleChangeContext({
            pageContentFullHeight: value
        })
    }

    const handleSetPageContentFullWidth = (value: contextValueType) => {
        handleChangeContext({
            pageContentFullWidth: value
        })
    }

    const handleSetPageContentInverseMode = (value: contextValueType) => {
        handleChangeContext({
            pageContentInverseMode: value
        })
    }

    const handleSetPageHeaderMegaMenu = (value: contextValueType) => {
        handleChangeContext({
            pageHeaderMegaMenu: value
        })
    }

    const handleSetPageHeaderLanguageBar = (value: contextValueType) => {
        handleChangeContext({
            pageHeaderLanguageBar: value
        })
    }

    const handleSetPageFooter = (value: contextValueType) => {
        handleChangeContext({
            pageFooter: value
        })
    }

    const handleSetPageTopMenu = (value: contextValueType) => {
        handleChangeContext({
            pageTopMenu: value
        })
    }

    const toggleMobileTopMenu = (e: Event) => {
        e.preventDefault()
        handleChangeContext({
            pageMobileTopMenu: !pageMobileTopMenu
        })
    }

    const handleSetPageTwoSidebar = (value: contextValueType) => {
        handleChangeContext({
            pageTwoSidebar: value
        })
    }

    const handleSetPageBoxedLayout = (value: contextValueType) => {
        if (value === true) {
            document.body.classList.add('boxed-layout')
        } else {
            document.body.classList.remove('boxed-layout')
        }
    }

    const handleSetBodyWhiteBg = (value: contextValueType) => {
        if (value === true) {
            document.body.classList.add('bg-white')
        } else {
            document.body.classList.remove('bg-white')
        }
    }

    const toggleSidebarMinify = (e) => {
        e.preventDefault()
        handleChangeContext({
            pageSidebarMinify: !pageSidebarMinify
        })
    }

    const scrollHandler = useCallback(() => {
        // if (window.scrollY > 0) {
        //     // handleChangeContext({
        //     //     hasScroll: true
        //     // })
        // } else {
        //     console.log('here: ')
        //     // handleChangeContext({
        //     //     hasScroll: false
        //     // })
        // }
        // const elm = document.getElementsByClassName('nvtooltip')
        // for (let i = 0; i < elm.length; i++) {
        //     elm[i].classList.add('d-none')
        // }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => scrollHandler())
        return () => {
            window.removeEventListener('scroll', () => null)
        }
    }, [scrollHandler])

    return (
        <ContextProvider
            value={{
                ...values,
                handleFloatSubMenuOnMouseOver,
                handleFloatSubMenuOnMouseOut,
                handleSidebarOnMouseOver,
                handleSidebarOnMouseOut,
                handleFloatSubMenuClick,
                handleSetPageContent,
                handleSetPageContentClass,
                handleSetPageContentFullHeight,
                handleSetPageContentFullWidth,
                handleSetPageContentInverseMode,
                handleSetPageHeader,
                handleSetPageHeaderMegaMenu,
                handleSetPageHeaderLanguageBar,
                handleSetPageFooter,
                handleSetPageTopMenu,
                toggleMobileTopMenu,
                handleSetPageTwoSidebar,
                handleSetPageBoxedLayout,
                handleSetBodyWhiteBg,
                toggleRightSidebar,
                toggleSidebarMinify
            }}
        >
            {children}
        </ContextProvider>
    )
}

export default React.memo(AppContextProvider)
