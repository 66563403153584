import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

export const CircleLoader = styled(Skeleton)``

export const PhotoLoader = styled(Skeleton)`
    width: 220px;
    height: 220px;
    border-radius: 100%;
`
export const ThumnailLoader = styled(Skeleton)`
    width: 40px;
    height: 40px;
    border-radius: 100%;
`
export const ButtonLoader = styled(Skeleton)`
    width: 120px;
`

export const TextRowLoader = styled(Skeleton)`
    width: 100%;
    height: 20px;
    margin-bottom: 5px;
`
