import React from 'react'
import { Pie } from 'react-chartjs-2'
import { useTrikosPerState } from '@services/jarvis/hooks'
import ChartBase from '@core/components/charts/chart-base'
import { useMemo } from 'react'
import { Badge } from './styled'

const colorsMap = {
    RequiredDocumentation: '#ef6c00',
    SelectingServices: '#0288d1',
    Rejected: '#c62828',
    Suspended: '#9c27b0',
    RecoveryProcess: '#00796b',
    PersonalInformation: '#9575cd',
    Registering: '#90caf9',
    Active: '#388e3c',
    activating: '#81c784'
}
// typeof colorsMap
const TrikosPerState: React.FC = () => {
    const { data, loading } = useTrikosPerState()

    const dataSetBuilt = useMemo(() => {
        if (!data) return []
        return data.reduce(
            (currentSet, currentItem) => {
                currentSet.data.push(currentItem.total)
                currentSet.colors.push(`${colorsMap[currentItem.workflow]}cf`)
                currentSet.borders.push(colorsMap[currentItem.workflow])
                currentSet.labels.push(currentItem.workflow)
                return currentSet
            },
            {
                borders: [],
                colors: [],
                labels: [],
                data: []
            } as { colors: string[]; labels: string[]; data: number[]; borders: string[] }
        )
    }, [data])
    return (
        <ChartBase title="Registered trikos" loading={loading}>
            <div className="flex flex-row flex-wrap mb-4">
                {data &&
                    Array.isArray(data) &&
                    data.map((item) => (
                        <Badge
                            className="badge"
                            style={{ backgroundColor: colorsMap[item.workflow] }}
                        >{`${item.workflow} ( ${item.total} )`}</Badge>
                    ))}
            </div>
            <Pie
                datasetIdKey="id"
                options={{ plugins: { legend: { position: 'bottom', display: false } } }}
                data={{
                    labels: dataSetBuilt.labels,
                    datasets: [
                        {
                            label: 'Registered trikos',
                            data: dataSetBuilt.data,
                            backgroundColor: dataSetBuilt.colors,
                            borderColor: dataSetBuilt.borders
                        }
                    ]
                }}
            />
        </ChartBase>
    )
}

export default TrikosPerState
