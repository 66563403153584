import { Button } from '@alejandro.devop/bt-components'
import TabsRenderer from './TabsRenderer'
import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { Context } from './DetailContext'

const ClientDetail: React.FC<{ clientId: any; onClose: () => void }> = ({ clientId, onClose }) => {
    const loading = false
    return (
        <Context.Provider value={{ clientId }}>
            <Modal toggle={() => onClose()} isOpen size="xl">
                <ModalHeader>View Client Profile</ModalHeader>
                <ModalBody>
                    <TabsRenderer loading={loading}></TabsRenderer>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => onClose()}>Close</Button>
                </ModalFooter>
            </Modal>
        </Context.Provider>
    )
}

export default ClientDetail
