import * as React from 'react'
import { PanelBase, PanelBody, PanelHeader, PanelFooter } from './PanelBase'
import { Props } from './panel-types'

type PanelProps = Props & {
    footer?: React.ReactNode
    title?: string
    expanded?: boolean
}

/**
 * Full panel to avoid import all separated parts.
 * @param children
 * @param footer
 * @param title
 * @param _props
 * @constructor
 */
const Panel: React.FC<PanelProps> = ({ children, footer, title, ..._props }) => {
    return (
        <PanelBase {..._props}>
            {title && <PanelHeader>{title}</PanelHeader>}
            <PanelBody>{children}</PanelBody>
            {footer && <PanelFooter>{footer}</PanelFooter>}
        </PanelBase>
    )
}

Panel.defaultProps = {
    expanded: false
}

export default Panel
