import React from 'react'
import Breadcrumbs from '@core/components/breadcrumbs'
import config from './page.config'
import { ServicesList } from '@core/components'

const TrikosRegisteredPage: React.FC = () => {
    const { breadCrumbs } = config
    return (
        <>
            <Breadcrumbs paths={breadCrumbs} />
            <ServicesList />
        </>
    )
}

export default TrikosRegisteredPage
