import React from 'react'
import { RequiredDocType } from '../../types'
import ImagePreview from './ImagePreview'
import { Alert } from 'reactstrap'
import { useCacheConfig } from '@core/hooks'

const LicenseRender: React.FC<{ docItem: RequiredDocType }> = ({ docItem }) => {
    const [frontFile, backFile] = (docItem.attributes.url_download_file || '')?.split(',')
    const { data, updateKey } = useCacheConfig(docItem.attributes.triko_id)

    const handleRotate = (key: string, rotation: number) => {
        updateKey(key, rotation)
    }

    return (
        <div>
            <Alert>Click over the image to see it zoomed</Alert>
            <div className="px-4">
                <h5 className="text-center mb-4">Front image</h5>
                <ImagePreview
                    imageUrl={frontFile}
                    allowViewer
                    allowDownload
                    initialRotation={data.licenseFrontRotation}
                    onRotate={(rotation) => {
                        handleRotate('licenseFrontRotation', rotation || 0)
                    }}
                />
            </div>
            <hr />
            <div className="px-4">
                <h5 className="text-center mb-4">Rear image</h5>
                <ImagePreview
                    imageUrl={backFile}
                    allowViewer
                    allowDownload
                    initialRotation={data.licenseRearRotation}
                    onRotate={(rotation) => {
                        handleRotate('licenseRearRotation', rotation || 0)
                    }}
                />
            </div>
        </div>
    )
}

export default LicenseRender
