import * as React from 'react'
import { Provider } from './ConfigurationContext'

const { REACT_APP_NAME } = process.env

interface Props {
    children: React.ReactNode
    debugging?: boolean | undefined
}

/**
 * This component allows to fetch and pass configurations to the application
 * @version 1.0.0
 * @author Alejandro <alejandro.devop@gmail.com>
 * @param children
 * @param debugging
 * @constructor
 */
const ConfigurationProvider: React.FC<Props> = ({ children, debugging }) => {
    return (
        <Provider
            value={{
                debugging,
                appName: REACT_APP_NAME
            }}
        >
            {children}
        </Provider>
    )
}

export default ConfigurationProvider
