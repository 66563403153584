import React from 'react'
import SmartGrid from '@alejandro.devop/bootstrap-smart-grid'
import Panel from '@core/panel'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'
import config from './grid.config'

const ServicesList: React.FC = () => {
    const t = useTranslate('trikos')
    return (
        <>
            <Panel title="services_manage">
                <SmartGrid
                    headerTextProcessor={(k: string) => t(k as DictionaryKeyType)}
                    textProcessor={(k: string, replacements) => {
                        const { key, value } = replacements || {}
                        const valuesToReplace = {}
                        if (key) {
                            valuesToReplace[key] = value
                        }
                        return t(k as DictionaryKeyType, {
                            ignoreScope: true,
                            replacements: valuesToReplace
                        })
                    }}
                    config={config}
                />
            </Panel>
        </>
    )
}

export default ServicesList
