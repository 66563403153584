import React from 'react'
import PDFPreview from './PDFPreview'

interface FileDataPreviewerPros {
    fileData?: string
    extension?: string
    size?: number
}

const FileDataPreviewer: React.FC<FileDataPreviewerPros> = ({ extension, fileData }) => {
    if (extension === 'pdf') {
        return <PDFPreview url={fileData} />
    }
    return null
}

export default FileDataPreviewer
