import React from 'react'
import { PersonType } from './types'
import { Table, Card, CardBody } from 'reactstrap'
import { Button } from '@alejandro.devop/bt-components'

const ClientInfo: React.FC<{ client?: PersonType }> = ({ client }) => {
    return (
        <Card className="mb-4">
            <CardBody>
                <h3>Solicitado por (Cliente)</h3>
                <Table>
                    <tbody>
                        <tr>
                            <td>Documento ID</td>
                            <td>{client?.id_number}</td>
                        </tr>
                        <tr>
                            <td>Nombres</td>
                            <td>{client?.first_name}</td>
                        </tr>
                        <tr>
                            <td>Apellidos</td>
                            <td>{client?.last_name}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="flex justify-center">
                    <Button variant="info">Ver perfil</Button>
                </div>
            </CardBody>
        </Card>
    )
}

export default ClientInfo
