import React from 'react'
import Breadcrumbs from '@core/components/breadcrumbs'
import config from './page.config'
import { RegisteredClients } from 'components'

const ClientsManagePage: React.FC = () => {
    const { breadCrumbs } = config
    return (
        <>
            <Breadcrumbs paths={breadCrumbs} />
            <RegisteredClients />
        </>
    )
}

export default ClientsManagePage
