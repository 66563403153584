import React from 'react'
import { DataProviderContext } from './Context'
import { useGet } from '@alejandro.devop/react-json-api-client'
interface DataProviderProps {
    children: React.ReactNode
    url: AvailableEndpointsType
    filters?: { [key: string]: any }
    replacements?: { [key: string]: any }
    LoaderComponent?: React.FC
    include?: string[]
}

const DataProvider: React.FC<DataProviderProps> = ({
    children,
    url,
    filters,
    LoaderComponent,
    replacements,
    include
}) => {
    const [data, { loading, refresh }] = useGet<AvailableEndpointsType>(url, {
        filters: filters,
        replacements,
        include
    })
    return (
        <DataProviderContext.Provider value={{ data, loading, refresh }}>
            {loading ? LoaderComponent ? <LoaderComponent /> : '...Loading...' : children}
        </DataProviderContext.Provider>
    )
}

export default DataProvider
