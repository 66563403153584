import React from 'react'
import { RequiredDocType } from './types'
import { CardBody, Badge } from 'reactstrap'
import { IconWrapper, CardCustom } from './styled'
import { Icon, Button } from '@alejandro.devop/bt-components'
import { useTranslate } from '@core/hooks'
import classnames from 'classnames'
import moment from 'moment'

const DocumentCard: React.FC<{ documentItem: RequiredDocType; onViewDocument: () => void }> = ({
    documentItem,
    onViewDocument
}) => {
    const lang = 'es'
    const t = useTranslate()
    if (!documentItem.attributes.requirement) {
        return null
    }

    const { attributes } = documentItem
    const { requirement, workflow_transition_id } = attributes
    const date = attributes.created_at
    const getStatus = () => {
        if (workflow_transition_id.toString() === '18') {
            return <Badge color="info">{t('documents.pending')}</Badge>
        } else if (workflow_transition_id.toString() === '19') {
            return <Badge color="success">{t('documents.approved')}</Badge>
        } else if (workflow_transition_id.toString() === '20') {
            return <Badge color="danger">{t('documents.rejected')}</Badge>
        }
        return 'undefined'
    }
    const currentWorkflow = workflow_transition_id.toString()
    return (
        <CardCustom
            className={classnames({
                'mb-2': true,
                cardPending: currentWorkflow === '18',
                cardApproved: currentWorkflow === '19',
                cardRejected: currentWorkflow === '20'
            })}
        >
            <CardBody>
                <div className="flex flex-row">
                    <IconWrapper className="bg-gray-200 flex justify-center items-center">
                        <Icon type="file-contract" className="icon" />
                    </IconWrapper>
                    <div className="flex-1">
                        <h5 className="mb-0">{requirement?.name[lang]}</h5>
                        {requirement?.helptext && (
                            <p className="mb-0">{requirement?.helptext[lang]}</p>
                        )}
                        <p className="mb-0">
                            {t('general.updatedAt')}{' '}
                            {moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm a')}
                        </p>
                        {getStatus()}
                    </div>
                    <div className="pl-12 flex items-end">
                        <Button onClick={onViewDocument} variant="info">
                            {t('documents.viewDocument')}
                        </Button>
                    </div>
                </div>
            </CardBody>
        </CardCustom>
    )
}

export default DocumentCard
