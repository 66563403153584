import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

export const IconLoader = styled(Skeleton)`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
`
export const TextWrapper = styled.div`
    flex: 1;
    height: 100px;
`
export const TextLine = styled(Skeleton)``

export const IconWrapper = styled.div``

export const ServiceIconWrapper = styled.div``

export const ServiceContentWrapper = styled.div`
    flex: 1;
`
export const ServiceIcon = styled.img`
    width: 50px;
    height: 50px;
    margin-right: 20px;
`
