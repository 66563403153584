import React, { useRef, useEffect, useState } from 'react'
import { Backdrop } from './styled'
import { Progress } from 'reactstrap'

const LoadingCurtain: React.FC<{ children?: React.FC; text?: string }> = ({ children, text }) => {
    const [shouldRemoveClass, setShouldRemoveClass] = useState(false)
    const backdropRef = useRef<any>()
    useEffect(() => {
        if (backdropRef.current) {
            const parent = backdropRef.current.parentNode
            if (!parent.classList.contains('relative')) {
                setShouldRemoveClass(true)
            }
            parent.classList.add('relative')
        }
        return () => {
            if (backdropRef.current) {
                const parent = backdropRef.current.parentNode
                if (shouldRemoveClass) {
                    parent.classList.remove('relative')
                }
            }
        }
    }, [shouldRemoveClass])
    return (
        <Backdrop ref={backdropRef}>
            {children}
            <div className="w-full text-center px-12">
                <Progress value={100} striped animated>
                    {text}
                </Progress>
            </div>
        </Backdrop>
    )
}

export default LoadingCurtain
