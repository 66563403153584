import React, { useMemo } from 'react'
import { RequiredDocType } from '../../types'
import { ImagePreviewImage } from './styled'
import useDataProvider from '@core/hooks/use-data-provider'
import BankInformationSchema from '@schemas/bank.schema'
import { Table } from 'reactstrap'

const BankInformationRender: React.FC<{ docItem: RequiredDocType }> = ({ docItem }) => {
    const { url_download_file, attrs } = docItem.attributes
    const { bank, accountNumber, phonenumber } = attrs || {}
    const { providedData } = useDataProvider<BankInformationSchema[]>()
    type OutputedBankType = { [k: string]: string }
    const banksMap = useMemo(
        () =>
            providedData?.reduce((banks, currentBank) => {
                banks[currentBank.id] = currentBank.attributes.name
                return banks
            }, {} as OutputedBankType),
        [providedData]
    )
    if (!banksMap) {
        return null
    }
    return (
        <div>
            <>
                {Boolean(url_download_file) && <ImagePreviewImage src={url_download_file} />}
                <Table striped bordered>
                    <tbody>
                        {bank && (
                            <tr>
                                <td>Bank</td>
                                <td>{bank ? banksMap[bank] : 'undefined'}</td>
                            </tr>
                        )}
                        {accountNumber && (
                            <tr>
                                <td>Account number</td>
                                <td>{accountNumber}</td>
                            </tr>
                        )}
                        {phonenumber && (
                            <tr>
                                <td>Nequi number</td>
                                <td>{phonenumber}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </>
        </div>
    )
}

export default BankInformationRender
