import * as React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

const { useState } = React

const DropdownNotification: React.FC = () => {
    const [dropdownOpen, setOpen] = useState<boolean>(false)
    const toggle = () => setOpen(!dropdownOpen)

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown" tag="li">
            <DropdownToggle className="f-s-14" tag="a">
                <i className="fa fa-bell" />
                <span className="label">0</span>
            </DropdownToggle>
            <DropdownMenu className="media-list dropdown-menu-right" tag="ul">
                <DropdownItem className="dropdown-header" tag="li" header>
                    NOTIFICATIONS (0)
                </DropdownItem>
                <DropdownItem className="text-center p-10">No notification found</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropdownNotification
