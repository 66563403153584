import styled from 'styled-components'
import { Icon as IconBase } from '@alejandro.devop/bt-components'

export const ProfilePhoto = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`

export const Icon = styled(IconBase)`
    background-color: #618833;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    & span {
    }
`

export const StatusWrapper = styled.span`
    display: inline-block;
    flex: 1;
`
