import { BreadCrumbType } from '@app-types/global'
import React from 'react'
import { Link } from 'react-router-dom'
import { useUrlResolver } from '@core/navigation/hooks'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'

interface BreadcrumbsProps {
    paths?: (BreadCrumbType | string)[]
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ paths }) => {
    const t = useTranslate()
    const resolveUrl = useUrlResolver()
    if (paths?.length === 0) {
        return null
    }
    return (
        <div>
            <ol className="breadcrumb">
                {paths?.map((pathInfo) => {
                    if (typeof pathInfo === 'object') {
                        const { label, path } = pathInfo
                        return (
                            <li className="breadcrumb-item" key={path}>
                                <Link to={resolveUrl(path)}>{t(label as DictionaryKeyType)}</Link>
                            </li>
                        )
                    }
                    return (
                        <li className="breadcrumb-item active" key={pathInfo}>
                            {t(pathInfo as DictionaryKeyType)}
                        </li>
                    )
                })}
            </ol>
        </div>
    )
}

export default Breadcrumbs
