import * as React from 'react'
import Breadcrumbs from '@core/components/breadcrumbs'
import ServiceRequests from 'components/service-requests'

interface RequestServicesManagePageProps {
    children?: React.ReactNode
}

const RequestServicesManagePage: React.FC<RequestServicesManagePageProps> = () => {
    return (
        <div>
            <Breadcrumbs />
            <ServiceRequests />
        </div>
    )
}

export default RequestServicesManagePage
