import React from 'react'
import { RequestType } from './types'
import { Table, Card, CardBody } from 'reactstrap'

const ServiceInfo: React.FC<{ info: RequestType }> = ({ info }) => {
    const { address, duration, application_date, created_at, attrs } = info.attributes
    const { tip, rating, deliveryFee, suggestedDate, negotiationAccepted, additionalInformation } =
        attrs
    return (
        <Card>
            <CardBody>
                <h3>Detalles del servicio</h3>
                <Table>
                    <tbody>
                        <tr>
                            <td>Dirección</td>
                            <td>{address}</td>
                        </tr>
                        <tr>
                            <td>Duración</td>
                            <td>{`${duration}h`}</td>
                        </tr>
                        <tr>
                            <td>Fecha</td>
                            <td>{application_date}</td>
                        </tr>
                        <tr>
                            <td>Fecha para ejecución</td>
                            <td>{application_date}</td>
                        </tr>
                        <tr>
                            <td>Fecha de envio</td>
                            <td>{created_at}</td>
                        </tr>
                        <tr>
                            <td>Propina</td>
                            <td>{tip}</td>
                        </tr>
                        <tr>
                            <td>Delivery fee</td>
                            <td>{deliveryFee}</td>
                        </tr>
                        {Boolean(suggestedDate) && (
                            <>
                                <tr>
                                    <td>Fecha sugerida</td>
                                    <td>{suggestedDate}</td>
                                </tr>
                                <tr>
                                    <td>Negociación aceptada</td>
                                    <td>{negotiationAccepted ? 'Si' : 'No'}</td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <td>Información adicional</td>
                            <td>{additionalInformation}</td>
                        </tr>
                        <tr>
                            <td>Calificación del Triko</td>
                            <td>{rating.triko}</td>
                        </tr>
                        <tr>
                            <td>Calificación del Cliente</td>
                            <td>{rating.client}</td>
                        </tr>
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}

export default ServiceInfo
