import React, { useState, useEffect } from 'react'
import { Badge } from 'reactstrap'
import { IconButton } from '@alejandro.devop/bt-components'
import { useTranslate } from '@core/hooks'

let timer: any = null

const useCopyRender = (initialFlags?: {
    [k: string]: boolean
}): { visibleFlags: any; renderCopyOption: (k: string, v?: string) => React.ReactNode } => {
    const t = useTranslate()
    const [visibleFlags, setVisibleFlags] = useState<{ [k: string]: boolean }>(initialFlags || {})

    const changeVisibleFlag = (flag: string, value: boolean) => {
        setVisibleFlags(
            Object.keys(visibleFlags).reduce((newConfig, currentKey) => {
                newConfig[currentKey] = currentKey === flag ? value : false
                return newConfig
            }, {})
        )
    }

    const copyToClipboard = async (visibleFlag: string, text?: string) => {
        try {
            if (navigator.clipboard && text) {
                await navigator.clipboard.writeText(text)
                changeVisibleFlag(visibleFlag || '', true)
                timer = setTimeout(() => {
                    changeVisibleFlag(visibleFlag || '', false)
                }, 2000)
            }
        } catch {
            // Handle error when copy
        }
    }
    const renderCopyOption = (flag: string, textToCopy?: string): React.ReactNode => {
        const visible = visibleFlags[flag]
        return (
            <>
                {!visible && (
                    <IconButton
                        onClick={() => copyToClipboard(flag, textToCopy?.toString())}
                        icon="clipboard"
                    />
                )}
                {visible && (
                    <Badge className="ml-8" color="success">
                        {t('general.textCopied')}
                    </Badge>
                )}
            </>
        )
    }
    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])
    return { visibleFlags, renderCopyOption }
}

export default useCopyRender
