import * as React from 'react'

const SearchForm: React.FC = () => {
    return (
        <li className="navbar-form">
            <form action="" method="POST" name="search_form">
                <div className="form-group">
                    <input type="text" className="form-control" placeholder={'search_anything'} />
                    <button type="submit" className="btn btn-search">
                        <i className="fa fa-search" />
                    </button>
                </div>
            </form>
        </li>
    )
}

export default SearchForm
