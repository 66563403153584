import React from 'react'
import useDataProvider from '@core/hooks/use-data-provider'
import { Table } from 'reactstrap'
import { useTranslate } from '@core/hooks'
import { Button } from '@alejandro.devop/bt-components'

const AddressesRender: React.FC = () => {
    const t = useTranslate()
    const { providedData } =
        useDataProvider<{
            includedObjects: {
                attributes: {
                    triko_id: number
                    title: string
                    address: string
                    description?: string
                    isMain: number
                    latitude: string
                    longitude: string
                    created_at: string
                }
            }[]
        }>()
    if (!providedData?.includedObjects) {
        return null
    }
    const [firstObject] = providedData?.includedObjects
    if (!firstObject) {
        return null
    }

    return (
        <div>
            <Table>
                <tbody>
                    <tr>
                        <td>{t('addresses.title')}</td>
                        <td>{firstObject.attributes.title}</td>
                    </tr>
                    <tr>
                        <td>{t('addresses.address')}</td>
                        <td>{firstObject.attributes.address}</td>
                    </tr>
                    <tr>
                        <td>{t('addresses.description')}</td>
                        <td>{firstObject.attributes.description}</td>
                    </tr>
                    <tr>
                        <td>{t('addresses.latitude')}</td>
                        <td>{firstObject.attributes.latitude}</td>
                    </tr>
                    <tr>
                        <td>{t('addresses.longitude')}</td>
                        <td>{firstObject.attributes.longitude}</td>
                    </tr>
                </tbody>
            </Table>
            <div className="py-8 text-center">
                <a
                    href={`https://www.google.com/maps/search/?api=1&query=${firstObject.attributes.latitude},${firstObject.attributes.longitude}`}
                    target="_blank"
                >
                    <Button variant="info">View on map</Button>
                </a>
            </div>
        </div>
    )
}

export default AddressesRender
