import { TranslationContext } from './translation-context'
import { DictionaryKeyType } from './translation.types'
import { useContext } from 'react'

type UseTranslateOptionsType = {
    ignoreScope?: boolean
    replacements?: { [k: string]: any }
}

export const useTranslate = (
    scoped?: string
): ((t: DictionaryKeyType, options?: UseTranslateOptionsType) => string) => {
    const { dictionary } = useContext(TranslationContext)
    return (textKey: DictionaryKeyType, options?: UseTranslateOptionsType) => {
        const { ignoreScope, replacements } = options || {}
        let translated =
            dictionary[scoped && !ignoreScope ? `${scoped}.${textKey}` : textKey] || textKey
        if (replacements && typeof replacements === 'object') {
            translated = Object.keys(replacements).reduce((result, currentKey) => {
                result = result.replace(
                    new RegExp(`%${currentKey}%`, 'g'),
                    replacements[currentKey]
                )
                return result
            }, translated)
        }
        return translated
    }
}
