import React, { useState } from 'react'
import SmartGrid from '@alejandro.devop/bootstrap-smart-grid'
import Panel from '@core/panel'
import { useTranslate } from '@core/hooks'
import config from './grid.config'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'
import RequestDetail from 'components/request-detail'

const ServiceRequests: React.FC = () => {
    const t = useTranslate('services')
    const [selectedRequest, setSelectedRequest] = useState<string | null>()
    const handleActionCall = (action: string, rowData: { id: string }) => {
        if (action === 'view') {
            setSelectedRequest(rowData.id)
        }
    }
    return (
        <>
            <Panel title="service_requests">
                <PerfectScrollbar>
                    <SmartGrid
                        headerTextProcessor={(k: string) => t(k as DictionaryKeyType)}
                        textProcessor={(k: string, replacements) => {
                            const { key, value } = replacements || {}
                            const valuesToReplace = {}
                            if (key) {
                                valuesToReplace[key] = value
                            }
                            return t(k as DictionaryKeyType, {
                                ignoreScope: true,
                                replacements: valuesToReplace
                            })
                        }}
                        config={config}
                        onAcctionCalled={handleActionCall}
                    />
                </PerfectScrollbar>
            </Panel>
            {Boolean(selectedRequest) && (
                <RequestDetail
                    requestId={selectedRequest}
                    onClose={() => setSelectedRequest(null)}
                />
            )}
        </>
    )
}

export default ServiceRequests
