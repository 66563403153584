import * as React from 'react'
import { Route } from 'react-router'
import SidebarNavList from './sidebar-nav-list'
import useAppSettings from '@core/app-context/hooks'
import { useMenuConfig } from '@core/hooks'

const { useState } = React

interface menuType {
    path?: string
    exact?: boolean
    search?: boolean
    title?: string
    children?: menuType[]
}

const SidebarNav: React.FC = () => {
    const { pageSidebarSearch } = useAppSettings()
    const menus = useMenuConfig()
    const [menuOptions, setMenuOptions] = useState<menuType[]>(menus.map((item): menuType => item))

    const [states, setStates] = useState<{ [key: string]: number }>({
        active: -1,
        clicked: -1
    })
    const { active, clicked } = states

    const updateState = (newStates: { [key: string]: number }) => setStates({ ...newStates })

    const handleExpand = (
        e: Event | { target?: unknown; preventDefault: () => void },
        i: number,
        match: unknown
    ) => {
        e.preventDefault()
        if (clicked === -1 && match) {
            updateState({
                active: -1,
                clicked: 1
            })
        } else {
            updateState({
                active: active === i ? -1 : i,
                clicked: 1
            })
        }
    }

    const handleSidebarSearch = (e: Event | { target: unknown }) => {
        const st = e.target as HTMLInputElement
        let searchValue = st.value
        searchValue = searchValue.toLowerCase()
        let newMenus: menuType[] = []
        if (searchValue !== '') {
            newMenus = menuOptions.filter((item) => {
                let title = item.title || ''
                title = title.toLowerCase()
                if (title.search(searchValue) > -1) {
                    item.search = true
                    return true
                } else {
                    if (item.children) {
                        for (let i = 0; i < item.children.length; i++) {
                            let title2 = item.children[i]['title'] || ''
                            title2 = title2.toLowerCase()

                            if (title2.search(searchValue) > -1) {
                                item.search = true
                                return true
                            }
                        }
                    }
                    return false
                }
            })
        } else {
            newMenus = menuOptions.filter((item) => {
                item.search = false
                return true
            })
        }
        setMenuOptions(newMenus)
    }

    return (
        <ul className="nav">
            {pageSidebarSearch && (
                <li className="nav-search">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Sidebar menu filter..."
                        onKeyUp={(e: Event | { target: unknown }) => handleSidebarSearch(e)}
                    />
                </li>
            )}
            <li className="nav-header">Navigation</li>
            {menuOptions.map((menu, i) => (
                <Route
                    path={menu.path}
                    exact={menu.exact}
                    key={i}
                    children={({ match }) => (
                        <SidebarNavList
                            data={menu}
                            key={i}
                            expand={(e: Event | { target: unknown; preventDefault: () => void }) =>
                                handleExpand(e, i, match)
                            }
                            active={i === active}
                            clicked={clicked}
                        />
                    )}
                />
            ))}
        </ul>
    )
}

export default SidebarNav
