const WorkflowMapIds = {
    '1': 'Registering',
    '2': 'SelectingServices',
    '3': 'PersonalInformation',
    '4': 'RequiredDocumentation',
    '5': 'Active',
    '6': 'Rejected',
    '7': 'RecoveryProcess',
    '8': 'Suspended',
    '9': 'Busy',
    '39': 'activating'
}

export default WorkflowMapIds
