import React, { useState } from 'react'
import AboutTheClient from './panels/about-client/AboutTheClient'
import ServiceRequestsPanel from './panels/service-requests'
import { NavItem, NavLink, Nav, TabContent, TabPane } from 'reactstrap'
import { useTranslate } from '@core/hooks'

const TabsRenderer: React.FC<{ loading: boolean }> = ({}) => {
    const [currentTab, setCurrenTab] = useState('about')
    const t = useTranslate()
    const [clientIdNumber, setClientIdNumber] = useState<any>()
    const [visiblePanels, setVisiblePanels] = useState<{
        about: boolean
        serviceRequests: boolean
    }>({ about: true, serviceRequests: false })

    const tabsToRender: { label: string; id: string }[] = [
        { label: t('detail.aboutTheTriko'), id: 'about' },
        { label: t('detail.serviceRequests'), id: 'serviceRequests' }
    ]

    const handleChangePanel = (tabId: any) => {
        if (visiblePanels[tabId] === false) {
            setVisiblePanels({ ...visiblePanels, [tabId]: true })
        }
        setCurrenTab(tabId)
    }

    return (
        <>
            <Nav tabs>
                {tabsToRender.map((tab, key) => (
                    <NavItem key={`nav-link${key}`}>
                        <NavLink
                            onClick={() => handleChangePanel(tab.id)}
                            active={currentTab === tab.id}
                        >
                            {tab.label}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={currentTab}>
                <TabPane tabId="about">
                    {visiblePanels.about && (
                        <AboutTheClient
                            onLoadInformation={({ data }: any) => {
                                const { attributes } = data || {}
                                const { personalInformation } = attributes
                                setClientIdNumber(personalInformation.idnumber)
                            }}
                        />
                    )}
                </TabPane>
                <TabPane tabId="serviceRequests">
                    {visiblePanels.serviceRequests && (
                        <ServiceRequestsPanel clientIDNumber={clientIdNumber} />
                    )}
                </TabPane>
            </TabContent>
        </>
    )
}

export default TabsRenderer
