export default {
    '21': '#64b5f6',
    '22': '#0d47a1',
    '23': '#5e35b1',
    '24': '#26a69a',
    '25': '#009688',
    '26': '#fdd835',
    '27': '#43a047',
    '28': '#aed581',
    '29': '#aed581',
    '30': '#00e676',
    '31': '#ef5350',
    '38': '#a5d6a7',
    '42': '#a5d6a7',
    '43': '#a5d6a7',
    '44': '#a5d6a7',
    '45': '#a5d6a7',
    '46': '#a5d6a7',
    '47': '#a5d6a7',
    '48': '#a5d6a7',
    '49': '#a5d6a7',
    '50': '#0097a7'
}
