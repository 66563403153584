import React, { useState } from 'react'
import SmartGrid from '@alejandro.devop/bootstrap-smart-grid'
import { useTranslate } from '@core/hooks'
import config from './grid.config'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'
import RequestDetail from 'components/request-detail'

const ServiceRequestsPanel: React.FC<{ trikoIDNumber }> = ({ trikoIDNumber }) => {
    const t = useTranslate('services')
    const [selectedRequest, setSelectedRequest] = useState<string | null>()
    const handleActionCall = (action: string, rowData: { id: string }) => {
        if (action === 'view') {
            setSelectedRequest(rowData.id)
        }
    }
    return (
        <>
            <SmartGrid
                headerTextProcessor={(k: string) => t(k as DictionaryKeyType)}
                textProcessor={(k: string, replacements) => {
                    const { key, value } = replacements || {}
                    const valuesToReplace = {}
                    if (key) {
                        valuesToReplace[key] = value
                    }
                    return t(k as DictionaryKeyType, {
                        ignoreScope: true,
                        replacements: valuesToReplace
                    })
                }}
                config={{
                    ...config,
                    defaultFilters: {
                        triko_id_number: trikoIDNumber
                    }
                }}
                onAcctionCalled={handleActionCall}
            />
            {Boolean(selectedRequest) && (
                <RequestDetail
                    requestId={selectedRequest}
                    disableTriko
                    onClose={() => setSelectedRequest(null)}
                />
            )}
        </>
    )
}

export default ServiceRequestsPanel
