import * as React from 'react'
import SessionProvider from '@alejandro.devop/redux-persistance'
import { AppContextProvider, AppWrapper } from '@core'
import AppNavigation from '@core/navigation'
import ConfigurationProvider from '@core/configuration-provider'
import './assets/main.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Chart, registerables } from 'chart.js'
import TranslationProvider from '@core/translation-provider'
const { REACT_APP_ENV } = process.env

Chart.register(...registerables)

const App: React.FC = () => {
    return (
        <AppContextProvider>
            <SessionProvider
                initialValues={{
                    devName: 'alejandro.devop@gmail.com'
                }}
                // driver="cookie"
            >
                <ConfigurationProvider debugging={REACT_APP_ENV === 'dev'}>
                    <TranslationProvider>
                        <AppWrapper>
                            <AppNavigation />
                        </AppWrapper>
                    </TranslationProvider>
                </ConfigurationProvider>
            </SessionProvider>
        </AppContextProvider>
    )
}

export default App
