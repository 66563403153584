import { useContext } from 'react'
import { ConfigurationContext } from './ConfigurationContext'

type ConfigurationType = {
    appName?: string
    debugging?: boolean
}
/**
 * This hook allows to access the configuration variables
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @returns ConfigurationType
 */
export const useConfig = (): ConfigurationType => {
    const { appName, debugging } = useContext(ConfigurationContext) as ConfigurationType
    return { appName, debugging }
}
