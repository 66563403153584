import * as React from 'react'
import Breadcrumbs from '@core/components/breadcrumbs'

interface SettingsTranslationsPageProps {
    children?: React.ReactNode
}

const SettingsTranslationsPage: React.FC<SettingsTranslationsPageProps> = () => {
    return (
        <div>
            <Breadcrumbs />
            <div>Content for your page</div>
        </div>
    )
}

export default SettingsTranslationsPage
