import * as React from 'react'
import { Link } from 'react-router-dom'
import useAppSettings from '@core/app-context/hooks'
import useSession from '@session/hooks'

const { useState } = React

const SidebarProfile: React.FC = () => {
    const { pageSidebarMinify } = useAppSettings()
    const [profileActive, setProfileActive] = useState<boolean>(false)
    const { store } = useSession()
    const { user } = store
    const { user_email } = user || {}
    const toggleProfile = () => setProfileActive(!profileActive)

    return (
        <>
            <ul className="nav">
                <li className={'nav-profile ' + (profileActive ? 'expand ' : '')}>
                    <Link to="/" onClick={toggleProfile}>
                        <div className="cover with-shadow" />
                        <div className="image image-icon bg-black text-grey-darker">
                            <i className="fa fa-user" />
                        </div>
                        <div className="info">
                            <b className="caret pull-right" />
                            {user_email}
                            <small>Administrador</small>
                        </div>
                    </Link>
                </li>
                <li>
                    <ul
                        className={
                            'nav nav-profile ' +
                            (profileActive && !pageSidebarMinify ? 'd-block ' : '')
                        }
                    >
                        <li>
                            <Link to="/">
                                <i className="fa fa-cog" /> Settings
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <i className="fa fa-pencil-alt" /> Send Feedback
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <i className="fa fa-question-circle" /> Helps
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </>
    )
}

export default SidebarProfile
