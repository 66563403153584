import React from 'react'

const PDFPreview: React.FC<{ url?: string }> = ({ url }) => {
    if (!url) {
        return null
    }
    return (
        <div className="h-96">
            <object data={url} type="application/pdf" width="100%" height="100%">
                <p>Alternative for the pdf file</p>
            </object>
        </div>
    )
}

export default PDFPreview
