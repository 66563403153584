import useSession from '@session/hooks'
import * as React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useTranslate } from '@core/hooks'
const { useState } = React

const DropdownProfile: React.FC = () => {
    const t = useTranslate()
    const [dropdownOpen, setOpen] = useState<boolean>(false)
    const { clear } = useSession()
    const toggle = () => setOpen(!dropdownOpen)

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user" tag="li">
            <DropdownToggle tag="a">
                <div className="  text-grey-darker">
                    <i className="fa fa-ellipsis-v" />
                </div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                <DropdownItem onClick={() => clear()}>{t('general.exit')}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropdownProfile
