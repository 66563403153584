import * as React from 'react'
import { Link } from 'react-router-dom'
import DropdownMegaMenu from './dropdown/mega'
import SearchForm from './search'
import DropdownNotification from './dropdown/notification'
import DropdownLanguage from './dropdown/language'
import DropdownProfile from './dropdown/profile'
import useAppSettings from '@core/app-context/hooks'
import NavbarLogo from './logo'
const { useState } = React

const Header: React.FC = () => {
    const [collapseMegaMenu, setCollapsed] = useState<boolean>(false)
    const {
        toggleMobileSidebar,
        toggleRightSidebar,
        toggleMobileRightSidebar,
        toggleMobileTopMenu,
        pageHeaderLanguageBar,
        pageHeaderMegaMenu,
        pageTwoSidebar,
        pageTopMenu,
        pageSidebar
    } = useAppSettings()
    const toggleMegaMenu = () => setCollapsed(!collapseMegaMenu)
    return (
        <div id="header" className="header _navbar-light">
            <div className="navbar-header">
                {pageTwoSidebar && (
                    <button
                        type="button"
                        className="navbar-toggle pull-left"
                        onClick={toggleMobileRightSidebar}
                    >
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                )}
                <Link to="/" className="navbar-brand">
                    <NavbarLogo />
                    {/* <span className="navbar-logo" /> <b>Color</b> Admin */}
                </Link>

                {pageHeaderMegaMenu && (
                    <button
                        type="button"
                        className="navbar-toggle pt-0 pb-0 mr-0"
                        onClick={toggleMegaMenu}
                    >
                        <span className="fa-stack fa-lg text-inverse">
                            <i className="far fa-square fa-stack-2x" />
                            <i className="fa fa-cog fa-stack-1x" />
                        </span>
                    </button>
                )}
                {pageTopMenu && pageSidebar && (
                    <button
                        type="button"
                        className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
                        onClick={toggleMobileTopMenu}
                    >
                        <span className="fa-stack fa-lg text-inverse">
                            <i className="far fa-square fa-stack-2x" />
                            <i className="fa fa-cog fa-stack-1x" />
                        </span>
                    </button>
                )}
                {!pageSidebar && pageTopMenu && (
                    <button type="button" className="navbar-toggle" onClick={toggleMobileTopMenu}>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                )}
                {pageSidebar && (
                    <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                )}
            </div>

            {pageHeaderMegaMenu && <DropdownMegaMenu collapse={collapseMegaMenu} />}

            <ul className="navbar-nav navbar-right">
                <SearchForm />
                <DropdownNotification />

                {pageHeaderLanguageBar && <DropdownLanguage />}

                <DropdownProfile />

                {pageTwoSidebar && <li className="divider d-none d-md-block" />}

                {pageTwoSidebar && (
                    <li className="d-none d-md-block">
                        <Link to="/" onClick={toggleRightSidebar} className="f-s-14">
                            <i className="fa fa-th" />
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Header
