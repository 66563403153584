import { GridConfig } from '@alejandro.devop/bootstrap-smart-grid'
import WorkflowMapIds from '@constants/workflows-map'
import endpointDefinitions from '@definitions/endpoints.def.json'

const config: GridConfig<keyof typeof endpointDefinitions> = {
    loadingLabel: 'Loading trikos',
    allowSelect: true,
    debug: false,
    id: 'clients-grid',
    url: 'clients.manage',
    columnVisibility: {
        id: true,
        phonenumber: true,
        email: true,
        identification: true,
        firstName: true,
        lastName: true,
        country: false,
        city: true
    },
    defaultFilters: {
        region: 1
    },
    displayFiltersFormatter: (filters: { label: string; value: any }[]) => {
        return filters.map((filter) => {
            if (filter.label === 'workflow_transition') {
                filter.value = WorkflowMapIds[filter.value]
            }
            return filter
        })
    },
    fields: {
        id: { label: 'ID', value: ({ id }: { id: number }) => `${id}` },
        phonenumber: {
            label: 'phoneNumber',
            value: 'attributes.user.phonenumber',
            sort: true,
            filter: true,
            filterOptions: {
                name: 'phonenumber'
            }
        },
        email: {
            label: 'email',
            value: 'attributes.user.email',
            sort: true,
            filter: true
        },
        identification: {
            label: 'phoneNumber',
            value: 'attributes.personalInformation.idnumber',
            sort: true,
            filter: true,
            filterOptions: {
                name: 'idnumber'
            }
        },
        firstName: {
            label: 'firstName',
            value: 'attributes.personalInformation.firstname',
            filter: true,
            filterOptions: {
                name: 'first_name'
            }
        },
        lastName: {
            label: 'lastName',
            value: 'attributes.personalInformation.lastname',
            filter: true,
            filterOptions: {
                name: 'last_name'
            }
        },
        country: {
            label: 'country',
            value: 'attributes.location.country'
        },
        city: {
            label: 'city',
            value: 'attributes.location.city',
            sort: true,
            filter: true,
            filterOptions: {
                name: 'city',
                type: 'select',
                selectOptions: {
                    ajax: true,
                    url: 'settings.cities',
                    advanced: true,
                    columnsMapping: {
                        label: 'name',
                        value: 'id'
                    },
                    requestConfig: {
                        filters: {
                            country_id: 48
                        }
                    }
                }
            }
        }
    },
    actions: [{ icon: 'eye', id: 'view', label: 'view' }],
    headers: {
        id: { label: 'ID', value: ({ id }: { id: number }) => `${id}` }
    }
}

export default config
