import * as React from 'react'

interface Props {
    /** The component that should be rendered by the route */
    Component?: any
}

/**
 * This component allows to pass props or do any login with the rendered screen by the route
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param Component
 * @constructor
 */
const RouteComponentRenderer: React.FC<Props> = ({ Component }) => {
    return <>{Component && <Component />}</>
}

export default RouteComponentRenderer
