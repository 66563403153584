import React, { useState } from 'react'
import {
    TimeLineBlock,
    TimeLineTimeWrapper,
    TimeLineIconWrapper,
    TimeLineContentWrapper,
    TimeLineIconItemWrapper,
    ContentWrapper
} from './styled'
import { Icon, IconButton } from '@alejandro.devop/bt-components'
import { Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap'
import moment from 'moment'
import WorkflowMapIds from '@constants/workflows-map'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'

interface TimelineProps {
    user: {
        history: { created_at: string; id: number; to: string; transition: string }[]
    }
}
const explanationTexts = {
    '0': 'User is creating an account',
    '1': 'workflowsExplanation.registeringHelp',
    '2': 'SelectingServices',
    '3': 'PersonalInformation',
    '4': 'RequiredDocumentation',
    '5': 'Active',
    '6': 'Rejected',
    '7': 'RecoveryProcess',
    '8': 'Suspended',
    '9': 'Busy',
    '39': 'activating'
}

/**
 * Renders the triko time line using the workflow history array to print it
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param props TimelineProps
 * @returns
 */
const TrikoTimeline: React.FC<TimelineProps> = ({ user }) => {
    const currentDate = moment()
    const t = useTranslate()
    const [visbileState, setVisibleState] = useState<any>()
    const getDate = (strDate: string) => {
        const date = moment.utc(strDate)
        if (date.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')) {
            return t('detail.today')
        }
        return date.format('YYYY-MM-DD')
    }
    const getTime = (strDate: string) => {
        const date = moment.utc(strDate)
        return date.format('hh:mm a')
    }

    const handleSelectHelp = (id: string | null) => {
        setVisibleState(id)
    }

    return (
        <ContentWrapper>
            <Alert color="warning">{t('detail.timeZoneError')}</Alert>
            {user?.history.map((item, key) => (
                <TimeLineBlock key={`time-line-${key}`} className="time-line-wrapper">
                    <TimeLineTimeWrapper>
                        <span className="date">{getDate(item.created_at)}</span>
                        <span className="time">{getTime(item.created_at)}</span>
                    </TimeLineTimeWrapper>
                    <TimeLineIconWrapper className="timeline-icon">
                        <TimeLineIconItemWrapper>
                            <Icon type="clock" className="tm-icon" />
                        </TimeLineIconItemWrapper>
                    </TimeLineIconWrapper>
                    <TimeLineContentWrapper>{t(WorkflowMapIds[item.to])}</TimeLineContentWrapper>
                    <TimeLineContentWrapper>
                        <IconButton
                            id={`pop-over-${item.to}`}
                            icon="info-circle"
                            onClick={() => handleSelectHelp(item.to as any)}
                        />
                    </TimeLineContentWrapper>
                </TimeLineBlock>
            ))}
            {visbileState !== null && visbileState !== undefined && (
                <Popover
                    isOpen
                    flip
                    target={`pop-over-${visbileState}`}
                    toggle={() => handleSelectHelp(null)}
                >
                    <PopoverHeader>
                        {visbileState === '0'
                            ? 'Creating account'
                            : t(WorkflowMapIds[visbileState] as DictionaryKeyType)}
                    </PopoverHeader>
                    <PopoverBody>
                        {t(explanationTexts[visbileState] as DictionaryKeyType)}
                    </PopoverBody>
                </Popover>
            )}
        </ContentWrapper>
    )
}

export default TrikoTimeline
