import { Button } from '@alejandro.devop/bt-components'
import TabsRenderer from 'components/triko-detail/TabsRenderer'
import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import { Context } from './DetailContext'

const TrikoDetail: React.FC<{ trikoId: any; onClose: () => void }> = ({ trikoId, onClose }) => {
    const loading = false
    return (
        <Context.Provider value={{ trikoId }}>
            <Modal toggle={() => onClose()} isOpen size="xl">
                <ModalHeader>View triko profile</ModalHeader>
                <ModalBody>
                    <TabsRenderer loading={loading}></TabsRenderer>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => onClose()}>Close</Button>
                </ModalFooter>
            </Modal>
        </Context.Provider>
    )
}

export default TrikoDetail
