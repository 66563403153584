import React, { useState } from 'react'
import Panel from '@core/panel'
import { ListGroup, Container, Col, Row, ListGroupItem } from 'reactstrap'
import { useGet } from '@alejandro.devop/react-json-api-client'
import { ImageIcon, TextWrapper, ItemWrapper } from './styled'
import { IconButton } from '@alejandro.devop/bt-components'
import ListLoader from './ListLoader'
import { useTranslate } from '@core/hooks'
import { ServiceTypeSchema } from '@schemas/servicetype.schema'
import ServicesList from './ServicesList'
import TrikosList from './TrikosList'
import { ServiceSchema } from '@schemas/service.schema'

const ServicesTypeList: React.FC = () => {
    const selectedLang = 'es'
    const [selectedCategory, setSelectedCategory] = useState<ServiceTypeSchema | null>(null)
    const [selectedService, setSelectedService] = useState<ServiceSchema | null>(null)
    const handleSelectService = (service: ServiceSchema) => {
        setSelectedService(null)
        setTimeout(() => {
            setSelectedService(service)
        }, 0)
    }
    const t = useTranslate()
    const [data, { loading }] = useGet<AvailableEndpointsType>('servicesTypes.list')

    const handleSelectItem = (item: ServiceTypeSchema) => {
        setSelectedCategory(item)
        setSelectedService(null)
    }

    return (
        <Panel title={t('services.serviceTypes')}>
            <Container fluid>
                <Row>
                    <Col xs="12" md="4">
                        {loading && <ListLoader />}
                        <ListGroup flush>
                            {data &&
                                Array.isArray(data) &&
                                data.map((item: any, key) => (
                                    <ListGroupItem
                                        key={`key-${key}`}
                                        action={true}
                                        tag="div"
                                        onClick={() => handleSelectItem(item)}
                                    >
                                        <ItemWrapper>
                                            <TextWrapper>
                                                <ImageIcon
                                                    src={item.attributes.icon}
                                                    alt={item.attributes.name[selectedLang]}
                                                />
                                                {item.attributes.name[selectedLang]}
                                            </TextWrapper>
                                            <IconButton icon="chevron-right" />
                                        </ItemWrapper>
                                    </ListGroupItem>
                                ))}
                        </ListGroup>
                    </Col>
                    <Col xs="12" md="4">
                        {Boolean(selectedCategory) && (
                            <ServicesList
                                categoryId={selectedCategory?.id}
                                onSelectService={handleSelectService}
                            />
                        )}
                    </Col>
                    <Col xs="12" md="4">
                        {Boolean(selectedService) && <TrikosList serviceId={selectedService?.id} />}
                    </Col>
                </Row>
            </Container>
        </Panel>
    )
}

export default ServicesTypeList
