import useDataProvider from '@core/hooks/use-data-provider'
import React from 'react'
import { RequiredDocType } from '../../types'
import { TrikoServicesSchema } from '@schemas/trikoService.schema'
import { ServiceWrapper, ServiceImage, ServiceContentWrapper } from './styled'
import { Button } from '@alejandro.devop/bt-components'
import useCurrency from '@core/hooks/use-currency'
import classnames from 'classnames'
import { usePatch } from '@alejandro.devop/react-json-api-client'
import { useTranslate } from '@core/hooks'
import LoadingCurtain from '@core/loaders/loader-curtain/LoadingCuirtain'

export const WORKFLOW_SERVICE_APPROVED = 52
export const WORKFLOW_SERVICE_REJECTED = 53

const ServiceRatesRender: React.FC<{ docItem: RequiredDocType }> = () => {
    const { loadingData, providedData, refresh } = useDataProvider<TrikoServicesSchema[]>()
    const t = useTranslate()
    const [sendRequest, loading] = usePatch<AvailableEndpointsType>(
        'requiredDocumentation.approveRejectService'
    )
    const format = useCurrency()
    if (loadingData || !providedData) {
        return null
    }
    const rateBy = (rateType: 'hour' | 'service') => {
        if (rateType === 'hour') {
            return <span>{t('detail.ratePerHour')}</span>
        } else if (rateType == 'service') {
            return <span>{t('detail.ratePerService')}</span>
        }
        return <span className="text-xs text-gray-300">{t('detail.notProvidedByBackend')}</span>
    }

    const changeWorkflow = async (recordId: any, serviceId: number, workflow: any) => {
        await sendRequest<
            PayloadType<{
                workflow_transition_id: any
                comment?: string
            }>
        >(
            {
                data: {
                    type: 'trikosservices',
                    id: recordId,
                    attributes: {
                        workflow_transition_id: workflow,
                        comment: ''
                    }
                }
            },
            {
                replacements: {
                    id: serviceId
                }
            }
        )
        if (refresh) {
            refresh()
        }
    }

    const handleApprove = async (recordId: any, serviceId: number) => {
        changeWorkflow(recordId, serviceId, WORKFLOW_SERVICE_APPROVED)
    }
    const handleReject = async (recordId: any, serviceId: number) => {
        changeWorkflow(recordId, serviceId, WORKFLOW_SERVICE_REJECTED)
    }
    //workflow_transition_id
    return (
        <>
            {providedData.map((item, key) => {
                const { workflow_transition_id } = item.attributes
                const isApproved = WORKFLOW_SERVICE_APPROVED === workflow_transition_id
                const isRejected = WORKFLOW_SERVICE_REJECTED === workflow_transition_id
                return (
                    <ServiceWrapper
                        key={`service-${key}}`}
                        className={classnames({
                            noServices: !item.attributes.price_base,
                            approved: isApproved,
                            rejected: isRejected
                        })}
                    >
                        {item && item.attributes && item.attributes.service && (
                            <>
                                <ServiceImage
                                    src={
                                        item.attributes.service.icon ||
                                        item.attributes.service.service_type.icon
                                    }
                                />
                                <ServiceContentWrapper>
                                    <p className="mb-0 pb-0 text-lg">
                                        {item.attributes.service.name}
                                    </p>
                                    <p className="mb-0 pb-0 text-sm">
                                        {item.attributes.service.service_type.name['es']}
                                    </p>
                                </ServiceContentWrapper>
                                <ServiceContentWrapper>
                                    <p className="text-lg">
                                        {item.attributes.price_base ? (
                                            format(item.attributes.price_base)
                                        ) : (
                                            <span className="text-red-500">
                                                {t('detail.rateNotDefined')}
                                            </span>
                                        )}
                                    </p>
                                    <p>
                                        {`${t('detail.rateBy')} : `}
                                        {rateBy(item.attributes.service.attrs.rateBy)}
                                    </p>
                                </ServiceContentWrapper>
                                <ServiceContentWrapper className="actions">
                                    <Button
                                        className="mb-2 btn-block"
                                        variant="success"
                                        disabled={!item.attributes.price_base || isApproved}
                                        onClick={() =>
                                            handleApprove(item.id, item.attributes.service_id)
                                        }
                                    >
                                        {t('document.service.approve')}
                                    </Button>
                                    <Button
                                        className="btn-block"
                                        variant="danger"
                                        disabled={!item.attributes.price_base || isRejected}
                                        onClick={() =>
                                            handleReject(item.id, item.attributes.service_id)
                                        }
                                    >
                                        {t('document.service.reject')}
                                    </Button>
                                </ServiceContentWrapper>
                            </>
                        )}
                        <hr />
                    </ServiceWrapper>
                )
            })}
            {loading && <LoadingCurtain />}
        </>
    )
}

export default ServiceRatesRender
