import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { Card } from 'reactstrap'

export const IconLoader = styled(Skeleton)`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
`
export const TextWrapper = styled.div`
    flex: 1;
    height: 100px;
`
export const TextLine = styled(Skeleton)``

export const IconWrapper = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-right: 15px;
    & .icon {
        font-size: 22px;
    }
`

export const ServiceIconWrapper = styled.div``

export const ServiceContentWrapper = styled.div`
    flex: 1;
`

export const ServiceIcon = styled.img`
    width: 50px;
    height: 50px;
    margin-right: 20px;
`
export const ColumnWrapper = styled.div`
    height: 600px;
`

export const CardCustom = styled(Card)`
    &.cardRejected {
        background-color: #ef9a9a8f;
    }
    &.cardPending {
        background-color: #bbdefb2f;
    }
    &.cardApproved {
        background-color: #a5d6a78f;
    }
`
