import ProfileLoader from 'components/triko-detail/loader/ProfileLoader'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import PersonalInformationLoader from './PersonalInformationLoader'
import FollowUpLoader from './FollowUpLoader'

const GeneralLoader: React.FC = () => {
    return (
        <div className="w-full">
            <Container fluid>
                <Row>
                    <Col xs="12" sm="6" md="4">
                        <ProfileLoader />
                    </Col>
                    <Col xs="12" sm="6" md="4">
                        <PersonalInformationLoader />
                    </Col>
                    <Col xs="12" sm="6" md="4">
                        <FollowUpLoader />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GeneralLoader
