import React from 'react'
import { IconLoader, IconWrapper, TextLine, TextWrapper } from './styled'
import _ from 'lodash'

const ServicesLoader: React.FC = () => {
    return (
        <>
            <h4 className="text-center">Trikos's services</h4>
            {_.times(3, (key) => (
                <div key={`loader-${key}`} className="flex flex-row">
                    <IconWrapper>
                        <IconLoader />
                    </IconWrapper>
                    <TextWrapper>
                        <TextLine />
                        <TextLine />
                        <TextLine />
                    </TextWrapper>
                </div>
            ))}
        </>
    )
}

export default ServicesLoader
