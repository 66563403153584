import React from 'react'
import { TrikoServiceObjectType } from './types'
import { Card, CardBody, Badge } from 'reactstrap'
import { ServiceIcon, ServiceContentWrapper } from './styled'
import { Button } from '@alejandro.devop/bt-components'
import useCurrency from '@core/hooks/use-currency/useCurrency'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'

const ServiceStatusesMap = {
    '51': 'Pending',
    '52': 'Approved',
    '53': 'Rejected'
}

const RenderTag: React.FC<{ status: number | string }> = ({ status }) => {
    if (status === 51) {
        return <Badge color="primary">pending</Badge>
    } else if (status === 52) {
        return <Badge color="green">approved</Badge>
    } else if (status === 53) {
        return <Badge color="danger">rejected</Badge>
    }
    return <>{ServiceStatusesMap[status]}</>
}

const ServiceCard: React.FC<{ service: TrikoServiceObjectType }> = ({ service }) => {
    const iconUrl = service.attributes.service.icon || service.attributes.service.service_type.icon
    const format = useCurrency()
    const t = useTranslate()
    return (
        <Card className="mb-2">
            <CardBody>
                <div className="flex flex-row">
                    <ServiceIcon src={iconUrl} />
                    <ServiceContentWrapper>
                        <div className="flex flex-row w-full">
                            <div className="flex-1">
                                <h5 className="mb-0">{service.attributes.service.name}</h5>
                                <p>{service.attributes.service.service_type.name['es']}</p>
                                <RenderTag
                                    status={t(
                                        `detail.servicesPanel.status${service.attributes.workflow_transition_id}` as DictionaryKeyType
                                    )}
                                />
                            </div>
                            <div className="flex flex-col items-end">
                                <h4>{t('detail.servicesPanel.rate')}</h4>
                                {service.attributes.price_base ? (
                                    <h5>{format(service.attributes.price_base)}</h5>
                                ) : (
                                    <p className="text-gray-400">
                                        {t('detail.servicesPanel.undefined')}
                                    </p>
                                )}
                            </div>
                            {service.attributes.workflow_transition_id === 51 && (
                                <div className="px-4">
                                    <h5 className="text-center">
                                        {t('detail.servicesPanel.approveOrReject')}
                                    </h5>
                                    <div className="flex justify-center items-center">
                                        <Button
                                            variant="success"
                                            disabled={!service.attributes.price_base}
                                            className="mx-2"
                                        >
                                            {t('detail.servicesPanel.approve')}
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="mx-2"
                                            disabled={!service.attributes.price_base}
                                        >
                                            {t('detail.servicesPanel.reject')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </ServiceContentWrapper>
                </div>
            </CardBody>
        </Card>
    )
}

export default ServiceCard
