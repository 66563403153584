import React, { useState } from 'react'
import { PersonType } from './types'
import { Table, Card, CardBody } from 'reactstrap'
import { Button } from '@alejandro.devop/bt-components'
import { TrikoDetail } from 'components'

const TrikoInfo: React.FC<{ triko?: PersonType }> = ({ triko }) => {
    const [trikoId, setTrikoId] = useState<any>()
    return (
        <>
            <Card className="mb-4">
                <CardBody>
                    <h3>Solicitado a (Triko)</h3>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Documento ID</td>
                                <td>{triko?.id_number}</td>
                            </tr>
                            <tr>
                                <td>Nombres</td>
                                <td>{triko?.first_name}</td>
                            </tr>
                            <tr>
                                <td>Apellidos</td>
                                <td>{triko?.last_name}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="flex justify-center">
                        <Button
                            variant="info"
                            onClick={() => {
                                setTrikoId(triko?.id)
                            }}
                        >
                            Ver perfil
                        </Button>
                    </div>
                </CardBody>
            </Card>
            {Boolean(trikoId) && <TrikoDetail trikoId={trikoId} onClose={() => setTrikoId(null)} />}
        </>
    )
}

export default TrikoInfo
