import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { makeNavigationStates } from '@core/navigation/hooks'
import { navigationStates } from '@config/navigation'
import useSession from '@session/hooks'
import RouteComponentRenderer from './RouteComponentRenderer'
import { LoginLayout, MainLayout } from '@layouts'
import NavigationProvider from './NavigationContext'
import { useConfig } from '@core/configuration-provider'

const useNavigationStates = makeNavigationStates(navigationStates)

/**
 * This component renders the navigation routes, it reads the file configuration from @config/navigation-states.tsx
 * using useNavigationStates hook the application determines which route group to render, you have to make sure only one
 * group will be rendered at time.
 * @version 1.0.0
 * @author Alejandro <alejandro.devop@gmail.com>
 * @constructor
 */
const AppNavigation: React.FC = () => {
    const { store } = useSession()
    const { debugging } = useConfig()
    const {
        initial,
        routes = [],
        layout
    } = useNavigationStates({ session: store || {}, debugging })
    const initialRoute = routes.find((route) => route.path === initial)
    let Layout: React.ComponentType = MainLayout
    if (layout === 'login') {
        Layout = LoginLayout
    }
    return (
        <Layout>
            <NavigationProvider navConfig={routes}>
                <Switch>
                    {initialRoute && (
                        <Route
                            exact
                            path="/"
                            render={() => <Redirect to={initialRoute.path || ''} />}
                        />
                    )}
                    {routes.map((route) => (
                        <Route
                            key={`route-${route.path}`}
                            path={route.path}
                            exact
                            render={() => <RouteComponentRenderer Component={route.component} />}
                        />
                    ))}
                    {initialRoute && (
                        <Route path="*" render={() => <Redirect to={initialRoute.path || ''} />} />
                    )}
                </Switch>
            </NavigationProvider>
        </Layout>
    )
}

export default React.memo(AppNavigation)
