import React from 'react'
import { ThumnailLoader, TextRowLoader } from './styled'
import _ from 'lodash'

const FollowUpLoader: React.FC = () => {
    return (
        <div className="w-full">
            {_.times(7, (item) => (
                <div key={`pi-${item}`} className="flex flex-row">
                    <ThumnailLoader />
                    <div className="flex-1 mt-2 ml-2">
                        <TextRowLoader />
                        <TextRowLoader />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FollowUpLoader
