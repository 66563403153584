import { authenticatedRoutes, unAuthenticatedRoutes, miscellaneous } from './routes'
import { RouteStateType } from '@app-types/route-type'

type configType = { session: SessionType | undefined; debugging?: boolean | undefined }

export default (config?: configType): RouteStateType => {
    const { session = {}, debugging } = config || {}
    return {
        authenticated: {
            condition: Boolean(session.isLogged) && !debugging,
            config: authenticatedRoutes,
            layout: 'main'
        },
        unAuthenticated: {
            condition: !session.isLogged,
            config: unAuthenticatedRoutes,
            layout: 'login'
        },
        testing: {
            condition: Boolean(session.isLogged) && Boolean(debugging),
            config: miscellaneous
        }
    }
}
