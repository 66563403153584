import React, { useState } from 'react'
import {
    TimeLineBlock,
    TimeLineTimeWrapper,
    TimeLineIconWrapper,
    TimeLineContentWrapper,
    TimeLineIconItemWrapper,
    ContentWrapper
} from './styled'
import { Icon, IconButton } from '@alejandro.devop/bt-components'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import moment from 'moment'
import WorkflowMapIds from '@constants/service-workflows-map'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'
import workflowLocales from '@locales/es/workflows.json'

interface TimelineProps {
    history?: {
        id: number
        workflow_transition_id: string
        to: string
        user: {
            email: string
            triko: 0 | 1
            client: 0 | 1
        }
        created_at: string
        updated_at: string
    }[]
}
const explanationTexts = {
    Pending: 'Quiere decir que el cliente ha enviado la solicitud pero aún no ha realizado el pago',
    Accepted:
        'Quiere decir que el pago ha sido completado por el cliente y el servicio por el triko',
    Payment: 'Quiere decir que el cliente aun está realizando el pago',
    OnMyWay: 'Quiere decir que el triko ya se encuentra en camino a la dirección del triko',
    OnYourDoor: 'Quiere decir que el triko ya se encuentra en la dirección del cliente',
    ConfirmStarting:
        'Quiere decir que el triko ha indicado que el servicio inició pero el cliente aún no lo ha hecho',
    Started:
        'Quiere decir que el cliente confirmó el inicio del servicio y ya empezó a correr el timer',
    ConfirmFinished:
        'Quiere decir que el triko indicó que el servicio ha llegado a su fin y se encuentra en espera de confirmación del cliente',
    QualifyServiceClient:
        'Quiere decir que el cliente se encuentra calificando el servicio pero aún no ha enviado la calificación',
    Finish: 'Quiere decir que el servicio oficialmente finalizó',
    Cancel: 'Quiere decir que el servicio fue cancelado',
    QualifyServiceTriko:
        'Quiere decir que el cliente ha enviado la calificación y ahora solo falta que el triko envie la suya',
    WaitingForTriko: '',
    GoingToShop: '',
    InTheShop: '',
    Shopping: '',
    WaitingForClient: '',
    PayingTheCart: '',
    PayingTheOrder: '',
    ConfirmPayment: '',
    OnGoing: 'En camino'
}

const TrikoTimeline: React.FC<TimelineProps> = ({ history }) => {
    const currentDate = moment()
    const t = useTranslate()
    const [visbileState, setVisibleState] = useState<any>()
    const getDate = (strDate: string) => {
        const date = moment.utc(strDate)
        if (date.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')) {
            return t('detail.today')
        }
        return date.format('YYYY-MM-DD')
    }
    const getTime = (strDate: string) => {
        const date = moment.utc(strDate)
        return date.format('hh:mm a')
    }

    const handleSelectHelp = (id: string | null) => {
        if (id !== visbileState) {
            setTimeout(() => {
                setVisibleState(id)
            }, 0)
        }
        setVisibleState(null)
    }

    return (
        <>
            {history && (
                <h4>
                    Estado actual: {workflowLocales[`service.${history[history.length - 1].to}`]}
                </h4>
            )}
            <ContentWrapper>
                {history?.map((item, key) => (
                    <TimeLineBlock key={`time-line-${key}`} className="time-line-wrapper">
                        <TimeLineTimeWrapper>
                            <span className="date">{getDate(item.created_at)}</span>
                            <span className="time">{getTime(item.created_at)}</span>
                        </TimeLineTimeWrapper>
                        <TimeLineIconWrapper className="timeline-icon">
                            <TimeLineIconItemWrapper>
                                <Icon type="clock" className="tm-icon" />
                            </TimeLineIconItemWrapper>
                        </TimeLineIconWrapper>
                        <TimeLineContentWrapper>{`${
                            workflowLocales[`service.${item.to}`]
                        }`}</TimeLineContentWrapper>
                        <TimeLineContentWrapper>
                            <IconButton
                                id={`pop-over-${item.to}`}
                                icon="info-circle"
                                onClick={() => handleSelectHelp(item.to as any)}
                            />
                        </TimeLineContentWrapper>
                    </TimeLineBlock>
                ))}
                {visbileState !== null && visbileState !== undefined && (
                    <Popover
                        isOpen
                        flip
                        target={`pop-over-${visbileState}`}
                        toggle={() => handleSelectHelp(null)}
                    >
                        <PopoverHeader>
                            {visbileState === '0'
                                ? 'Creating account'
                                : t(WorkflowMapIds[visbileState] as DictionaryKeyType)}
                        </PopoverHeader>
                        <PopoverBody>
                            {t(explanationTexts[visbileState] as DictionaryKeyType)}
                        </PopoverBody>
                    </Popover>
                )}
            </ContentWrapper>
        </>
    )
}

export default TrikoTimeline
