import HomePage from './home'
import TrikosRegistered from './trikos-registered'
import TrikosApproved from './trikos-approved'
import ServicesListPage from './services-list'
import ServicesTypesListPage from './services-types-list'
import ClientsManagePage from './clients-manage'
import SettingsTranslationsPage from './settings-translations'
import RequestServicesManagePage from './request-services-manage'
import RequiredDocumentsPage from './required-documents'
// do not remove this, end of pages import
export default {
    // prettier-ignore
    home: HomePage,
    'trikos.registered': TrikosRegistered,
    'trikos.approved': TrikosApproved,
    'settings.services': ServicesListPage,
    'settings.servicesTypes': ServicesTypesListPage,
    clientsManagePage: ClientsManagePage,
    settingsTranslationsPage: SettingsTranslationsPage,
    requestServicesManagePage: RequestServicesManagePage,
    requiredDocumentsPage: RequiredDocumentsPage
}
