import * as React from 'react'
import { Link, Route } from 'react-router-dom'
import { MouseEventHandler } from 'react'
import useAppSettings from '@core/app-context/hooks'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '../../../@core/translation-provider/translation.types'

const { useState } = React

type dataType = {
    badge?: boolean
    children?: dataType[]
    exact?: boolean
    highlight?: boolean
    icon?: string
    img?: string
    label?: string
    path?: string | null | any
    search?: boolean
    title?: string
}

interface Props {
    active?: number | boolean
    clicked?: number | boolean
    data: dataType
    expand?: MouseEventHandler | ((e: MouseEvent) => void) | any
}

const SidebarNavList: React.FC<Props> = ({ active, clicked, data = {}, expand }) => {
    const { handleSidebarOnMouseOver, handleSidebarOnMouseOut, pageSidebarMinified } =
        useAppSettings()
    const t = useTranslate('menu')
    const [states = {}, setStates] = useState<{ [key: string]: number | boolean | undefined }>({
        active: -1,
        clicked: -1
    })

    const handleExpand = (e: Event, index?: number) => {
        e.preventDefault()
        setStates({
            active: states.active === index ? -1 : index,
            clicked: 1
        })
    }

    const icon = data.icon && <i className={data.icon} />
    const img = data.img && (
        <div className="icon-img">
            <img src={data.img} alt="" />
        </div>
    )
    const caret = data.children && !data.badge && <b className="caret" />
    const label = data.label && <span className="label label-theme m-l-5">{data.label}</span>
    const badge = data.badge && <span className="badge pull-right">{data.badge}</span>
    const title = data.title && (
        <span>
            {t(data.title as DictionaryKeyType)} {label}
        </span>
    )
    const highlight = data.highlight && <i className="fa fa-paper-plane text-theme" />

    return (
        <>
            <Route
                path={data.path}
                exact={data.exact}
                children={({ match }) => (
                    <li
                        className={
                            (match ? 'active ' : '') +
                            (active || (clicked === -1 && match) || data.search
                                ? 'expand '
                                : 'closed ') +
                            (data.children ? 'has-sub ' : '')
                        }
                    >
                        {data.children ? (
                            <Link
                                to={data.path}
                                onMouseOver={(e) => handleSidebarOnMouseOver(e, data)}
                                onMouseOut={(e) => handleSidebarOnMouseOut(e, data)}
                                onClick={expand}
                            >
                                {caret} {badge} {img} {icon} {title} {highlight}
                            </Link>
                        ) : (
                            <Link to={data.path}>
                                {caret} {img} {icon} {badge} {title} {highlight}
                            </Link>
                        )}
                        {data.children && (
                            <ul
                                className={
                                    'sub-menu ' +
                                    ((active || (clicked === -1 && match) || data.search) &&
                                    !pageSidebarMinified
                                        ? 'd-block '
                                        : 'd-none')
                                }
                            >
                                {data.children &&
                                    data.children.map((submenu, subMenuKey) => {
                                        return (
                                            <SidebarNavList
                                                data={submenu}
                                                key={`sub-menu-${subMenuKey}`}
                                                expand={(e) => handleExpand(e, subMenuKey)}
                                                active={subMenuKey === states.active}
                                                clicked={states.clicked}
                                            />
                                        )
                                    })}
                            </ul>
                        )}
                    </li>
                )}
            />
        </>
    )
}

export default SidebarNavList
