import { useTranslate } from '@core/hooks'
import React from 'react'
import { Table } from 'reactstrap'

interface PersonalInfoProps {
    idNumber: string
    firstName: string
    lastName: string
    birthDate: string
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({
    firstName,
    lastName,
    birthDate,
    idNumber
}) => {
    const t = useTranslate()
    const renderUndefined = (field: string) =>
        field ? field : <span className="text-gray-400">{t('general.undefined')}</span>
    return (
        <>
            <div className="w-full">
                <div className="mt-4">
                    <h4 className="text-center">{t('detail.personalInformation')}</h4>
                    <Table>
                        <tbody>
                            <tr>
                                <td>{t('detail.idNumber')}</td>
                                <td>{renderUndefined(idNumber)}</td>
                            </tr>
                            <tr>
                                <td>{t('detail.firstName')}</td>
                                <td>{renderUndefined(firstName)}</td>
                            </tr>
                            <tr>
                                <td>{t('detail.lastName')}</td>
                                <td>{renderUndefined(lastName)}</td>
                            </tr>
                            <tr>
                                <td>{t('detail.birthDate')}</td>
                                <td>{renderUndefined(birthDate)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default PersonalInfo
