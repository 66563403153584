import React from 'react'
import Breadcrumbs from '@core/components/breadcrumbs'
import config from './page.config'
import { RegisteredTrikos } from '@core/components'

const TrikosApproved: React.FC = () => {
    const { breadCrumbs } = config
    return (
        <>
            <Breadcrumbs paths={breadCrumbs} />
            <RegisteredTrikos />
        </>
    )
}

export default TrikosApproved
