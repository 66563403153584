import * as React from 'react'
import Header from './header'
import SideBar from './sidebar'
import SidebarRight from './sidebar-right'
import useAppSettings from '@core/app-context/hooks'
import Footer from './footer'
import FloatSubMenu from './float-submenu'
import { BrowserConfig } from '../commons'

interface Props {
    children?: React.ReactNode
}

/**
 * This component renders a full layout for the application with the menu
 * @version 1.0.0
 * @author Alejandro <alejandro.devop@gmail.com>
 * @param children
 * @constructor
 */
const MainLayout: React.FC<Props> = ({ children }) => {
    const {
        pageContentFullWidth,
        pageContentInverseMode,
        pageContentClass,
        pageHeader,
        pageSidebar,
        pageFooter,
        pageTwoSidebar
    } = useAppSettings()
    return (
        <>
            <BrowserConfig />
            {pageHeader && <Header />}
            {pageSidebar && <SideBar />}
            {pageTwoSidebar && <SidebarRight />}
            <div
                className={
                    'content ' +
                    (pageContentFullWidth ? 'content-full-width ' : '') +
                    (pageContentInverseMode ? 'content-inverse-mode ' : '') +
                    pageContentClass
                }
            >
                {children}
            </div>
            {pageFooter && <Footer />}
            <FloatSubMenu />
        </>
    )
}

export default React.memo(MainLayout)
