import React from 'react'
import { PhotoLoader, TextRowLoader, ButtonLoader } from './styled'

const ProfileLoader: React.FC = () => {
    return (
        <div className="w-full">
            <div className="mt-2 flex flex-col items-center flex-1 mb-2">
                <PhotoLoader />
                <ButtonLoader />
            </div>
            <TextRowLoader />
            <TextRowLoader />
            <TextRowLoader />
            <TextRowLoader />
            <TextRowLoader />
        </div>
    )
}

export default ProfileLoader
