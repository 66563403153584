import * as React from 'react'
import useSession from '@session/hooks'
const Footer: React.FC = () => {
    const year = new Date().getFullYear()
    const { store } = useSession()
    const { devName } = store
    return (
        <div id="footer" className="footer">
            &copy; {year} Triko App - {devName}
        </div>
    )
}

export default Footer
