import React, { useState, useEffect } from 'react'
import { useGet } from '@alejandro.devop/react-json-api-client'
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem, Progress } from 'reactstrap'
import { ImageIcon, TextWrapper, ItemWrapper } from './styled'
import { Button, IconButton, TextField } from '@alejandro.devop/bt-components'
import { ServiceTypeSchema } from '@schemas/servicetype.schema'
import ListLoader from './ListLoader'
import { TrikoDetail } from 'components'

let timer: any = null

const TrikosList: React.FC<{ serviceId?: string | number }> = ({ serviceId }) => {
    const [data, setData] = useState<any[]>([])
    const [page, setPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedTriko, setSelectedTriko] = useState<any>(null)
    const [loaded, setLoaded] = useState(false)
    const [search, setSearch] = useState(false)
    /**
     * ToDo: Please, the backend should open this to more then 100 records per page
     */
    const [, { loading, meta, refresh }] = useGet<AvailableEndpointsType>('trikos.registered', {
        currentPage: page,
        pageSize: 10,
        filters: {
            service: `[${serviceId}]`
        },
        onCompleted: ({ data: newData }: any) => {
            if (search) {
                setData([...newData])
            } else {
                setData([...data, ...newData])
            }
            setLoaded(true)
        }
    })
    const totalPages = meta?.page['last-page'] || 1
    const handleSelectItem = (item: ServiceTypeSchema) => {
        console.log('item: ', item)
    }
    const handleViewMore = () => {
        let nextPage = page + 1
        if (page >= totalPages) {
            nextPage = totalPages
        }
        setPage(nextPage)
    }

    const triggerSearch = (term: string) => {
        if (term.length > 0) {
            refresh({
                filters: {
                    service: `[${serviceId}]`,
                    idnumber: term
                }
            })
        } else {
            setData([])
            refresh()
        }
        setSearch(false)
    }

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        setSearchQuery(target.value)
        setSearch(true)
        setPage(1)
        clearTimeout(timer)
        timer = setTimeout(() => {
            triggerSearch(target.value)
        }, 800)
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return (
        <Card>
            <CardHeader>
                {meta &&
                    meta.page.total &&
                    `${meta.page.total} Trikos registrados para este servicio`}
            </CardHeader>
            <CardBody>
                <TextField
                    label="Búscar triko"
                    onChange={handleInputChange}
                    value={searchQuery}
                    placeholder="Ingresa la cédula del triko"
                />
                <ListGroup flush>
                    {!loaded && loading && <ListLoader />}
                    {loaded &&
                        data &&
                        Array.isArray(data) &&
                        data.map((item: any, key) => (
                            <ListGroupItem
                                key={`key-${key}`}
                                action={true}
                                tag="div"
                                onClick={() => handleSelectItem(item)}
                            >
                                <ItemWrapper>
                                    <TextWrapper>
                                        {item.attributes.photoUrl && (
                                            <ImageIcon
                                                src={item.attributes.photoUrl}
                                                alt={item.attributes.personalInformation.firstname}
                                            />
                                        )}
                                        {`(${item.attributes.personalInformation.idnumber}) ${item.attributes.personalInformation.firstname} ${item.attributes.personalInformation.lastname}`}
                                    </TextWrapper>
                                    <IconButton icon="eye" onClick={() => setSelectedTriko(item)} />
                                </ItemWrapper>
                            </ListGroupItem>
                        ))}
                </ListGroup>
                <div className="text-center">
                    {page < totalPages && (
                        <>
                            {loading ? (
                                <Progress value={100} striped animated />
                            ) : (
                                <Button variant="info" onClick={() => handleViewMore()}>
                                    Ver más
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </CardBody>
            {Boolean(selectedTriko) && (
                <TrikoDetail trikoId={selectedTriko.id} onClose={() => setSelectedTriko(null)} />
            )}
        </Card>
    )
}

export default TrikosList
