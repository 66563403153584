import * as React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

const { useState } = React

const DropdownLanguage: React.FC = () => {
    const [dropdownOpen, setOpen] = useState<boolean>(false)
    const toggle = () => setOpen(!dropdownOpen)

    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="dropdown navbar-languager"
            tag="li"
        >
            <DropdownToggle className="dropdown-toggle" tag="a">
                <span className="flag-icon flag-icon-us m-r-5" title="us" />
                <span className="name d-none d-sm-inline">EN</span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                <DropdownItem>
                    <span className="flag-icon flag-icon-us m-r-5" title="us" /> English
                </DropdownItem>
                <DropdownItem>
                    <span className="flag-icon flag-icon-cn m-r-5" title="cn" /> Chinese
                </DropdownItem>
                <DropdownItem>
                    <span className="flag-icon flag-icon-jp m-r-5" title="jp" /> Japanese
                </DropdownItem>
                <DropdownItem>
                    <span className="flag-icon flag-icon-be m-r-5" title="be" /> Belgium
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="text-center">more options</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropdownLanguage
