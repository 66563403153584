import React from 'react'
import { Line } from 'react-chartjs-2'
import ChartBase from '@core/components/charts/chart-base'
import { Badge } from './styled'
import { useRequestsPerDate } from '@services/jarvis/hooks'
import moment from 'moment'

const colorsMap = {
    RequiredDocumentation: '#ef6c00',
    SelectingServices: '#0288d1',
    Rejected: '#c62828',
    Suspended: '#9c27b0',
    RecoveryProcess: '#00796b',
    PersonalInformation: '#9575cd',
    Registering: '#90caf9',
    Active: '#388e3c',
    activating: '#81c784'
}

// typeof colorsMap
const TrikosVSClients: React.FC = () => {
    // const { data, loading } = useTrikosPerState()
    const initialDate = moment().startOf('month').format('YYYY-MM-DD')
    const endDate = moment().endOf('month').format('YYYY-MM-DD')
    const { data: requestData } = useRequestsPerDate({ fromDate: initialDate, endDate })

    const loading = false
    const dates = (requestData || []).reduce((dates, currentItem) => {
        dates.push(currentItem.date)
        return dates
    }, [])
    const data: any = {
        clients: requestData
    }
    const dataSetBuilt: any = {
        labels: dates,
        clients: data.clients.map((item) => item.total)
    }
    return (
        <ChartBase title="Solicitudes este mes" loading={loading}>
            <div className="flex flex-row flex-wrap mb-4">
                {data &&
                    Array.isArray(data) &&
                    data.map((item) => (
                        <Badge
                            className="badge"
                            style={{ backgroundColor: colorsMap[item.workflow] }}
                        >{`${item.workflow} ( ${item.total} )`}</Badge>
                    ))}
            </div>
            <div style={{ height: 260 }}>
                <Line
                    datasetIdKey="id"
                    options={{
                        plugins: { legend: { position: 'bottom', display: false } },
                        maintainAspectRatio: false
                    }}
                    data={{
                        labels: dataSetBuilt.labels,
                        datasets: [
                            {
                                label: 'Clients',
                                data: dataSetBuilt.clients,
                                backgroundColor: '#039be58f',
                                fill: true,
                                tension: 0.4
                            }
                        ]
                    }}
                />
            </div>
        </ChartBase>
    )
}

export default TrikosVSClients
