import React, { useEffect } from 'react'
import { useConfig } from '@core/configuration-provider'

/**
 * Use this component to render or manipulate the browser properties
 * @version 1.0.0
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @returns React.ReactNode
 */
const BrowserConfig: React.FC = () => {
    const { appName = '' } = useConfig()
    useEffect(() => {
        document.title = appName
    }, [appName])
    return <></>
}

export default BrowserConfig
