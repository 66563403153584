import React from 'react'
import { Alert, Progress } from 'reactstrap'
import { useKeyboardListener, useForm as useFormAlt } from '@alejandro.devop/commons'
import { Button } from '@alejandro.devop/bt-components'
import { EmailField, PasswordField } from '@alejandro.devop/bt-components'
import { AuthPayloadSchema } from '@schemas/auth.schema'
import Logo from './Logo'

interface Props {
    authError: boolean
    loading: boolean
    onSubmit: (values: AuthPayloadSchema) => void
    toggleError: () => void
}

const LoginForm: React.FC<Props> = ({ loading, onSubmit, authError, toggleError }) => {
    const { detectEnter } = useKeyboardListener()

    const handleSubmitWithKeyboard = (e: React.KeyboardEvent, formValues: any) =>
        detectEnter(() => {
            onSubmit(formValues)
        })(e)

    const [formConfigAlt, { values, isValid, formErrors }] = useFormAlt<{
        email: string
        password: string
        rememberMe?: string
    }>({
        fields: {
            email: {
                autoComplete: 'off',
                otherProps: { bsSize: 'lg' },
                onKeyUp: handleSubmitWithKeyboard
            },
            password: {
                onKeyUp: handleSubmitWithKeyboard,
                otherProps: { bsSize: 'lg' }
            }
        },
        rules: {
            email: {
                email: true
            }
        },
        required: 'email|password',
        initialValues: {
            email: '',
            password: ''
        }
    })
    const handleSubmit = () => {
        onSubmit(values)
    }
    return (
        <>
            <div className="login-cover">
                <div
                    className="login-cover-image"
                    style={{ backgroundImage: 'url(/assets/img/login-bg/triko-bg.png)' }}
                />
            </div>
            <div className="login login-v2">
                <div className="card pb-10">
                    <div className="flex justify-center pt-10 mb-4">
                        <Logo src="assets/img/login-bg/triko-logo.png" />
                    </div>
                    <div className="login-content">
                        <Alert color="danger" isOpen={authError} toggle={toggleError} fade={false}>
                            User or password invalid
                        </Alert>
                        <EmailField {...formConfigAlt.email} validationMessage={formErrors.email} />
                        <PasswordField {...formConfigAlt.password} />
                        <div className="login-buttons">
                            {loading && (
                                <Progress animated value={100} className="mb-2" color="success" />
                            )}
                            <Button
                                onClick={handleSubmit}
                                disabled={loading || !isValid}
                                buttonType="submit"
                                className="btn btn-warning btn-block btn-lg"
                            >
                                Iniciar sesión
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginForm
