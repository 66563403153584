import { authenticated, miscellaneous as misc, unauthenticated } from './groups'
import { GroupType } from '@app-types/route-type'

export const authenticatedRoutes: GroupType = {
    initial: 'requiredDocumentsPage',
    routes: authenticated
}

export const unAuthenticatedRoutes: GroupType = {
    initial: 'login',
    routes: unauthenticated
}

export const miscellaneous: GroupType = {
    initial: 'testingHall',
    routes: misc
}
