import React from 'react'
import { ColumnLoader, LoaderWrapperDiv } from './styled'

const Loader: React.FC = () => {
    return (
        <>
            <LoaderWrapperDiv className="flex flex-row items-end">
                <ColumnLoader className="xs" />
                <ColumnLoader className="sm" />
                <ColumnLoader className="md" />
                <ColumnLoader className="lg" />
                <ColumnLoader className="xl" />
                <ColumnLoader />
            </LoaderWrapperDiv>
        </>
    )
}

export default Loader
