import React from 'react'
import Breadcrumbs from '@core/components/breadcrumbs'
import config from './page.config'
import ServicesTypeList from '@core/components/services-types-list/ServicesTypesList'

const ServicesTypesListPage: React.FC = () => {
    const { breadCrumbs } = config
    return (
        <>
            <Breadcrumbs paths={breadCrumbs} />
            <ServicesTypeList />
        </>
    )
}

export default ServicesTypesListPage
