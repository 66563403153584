import React, { useState, useEffect } from 'react'
import { useGet, usePost } from '@alejandro.devop/react-json-api-client'
import { Container, Card, CardBody, Col, Row, Alert } from 'reactstrap'
import GeneralLoader from '../../loader/GeneralLoader'
import { useMemo } from 'react'
import WorkflowMapIds from '@constants/workflows-map'
import workflows from '@locales/es/workflows.json'
import UserInfo from './UserInfo'
import { Button } from '@alejandro.devop/bt-components'
import TrikoTimeline from '../timeline'
import PersonalInfo from 'components/triko-detail/panels/about-triko/PersonalInformation'
import { ProfilePhoto } from './styled'
import { useDetailContext } from '../../hooks'
import useCacheConfig from '@core/hooks/use-cache-config/useCacheConfig'
import Zoom from 'react-medium-image-zoom'
import { TrikoDataType } from './types'
import { useTranslate } from '@core/hooks'
import ChangeTrikoStatus from './ChangeTrikoStatus'
import LoadingCurtain from '@core/loaders/loader-curtain/LoadingCuirtain'

let timer: any = null

const AboutTheTriko: React.FC<{ onLoadInformation: (idNumber: string) => void }> = ({
    onLoadInformation
}) => {
    const { trikoId } = useDetailContext()
    const [openChangeState, setOpenChangeState] = useState<boolean>(false)
    const [codeSend, setCodeSend] = useState<boolean>()
    const [sendPost, updating] = usePost<AvailableEndpointsType>('trikos.resendCode', {})
    const t = useTranslate()
    const {
        data: { profilePhotoRotation },
        updateKey
    } = useCacheConfig(trikoId)

    const [data, { loading, refresh }] = useGet<AvailableEndpointsType>('trikos.detail', {
        onCompleted: (data) => {
            onLoadInformation(data)
        },
        replacements: {
            id: trikoId
        },
        include: ['user:users->user|user_id']
    })

    const trikoData = useMemo(() => {
        return {
            id: (data as any)?.id,
            ...(data as any)?.attributes
        }
    }, [data])

    const { user, photoUrl, personalInformation, created_at, workflow } = (trikoData ||
        {}) as TrikoDataType
    const { firstname, lastname, birthdate, idnumber } = personalInformation || {}

    const handleSendCode = async () => {
        try {
            await sendPost<PayloadType>(
                {
                    data: {
                        type: 'users',
                        id: user.id
                    }
                },
                {
                    replacements: {
                        userId: user.id
                    }
                }
            )
            clearTimeout(timer)
            setCodeSend(true)
            timer = setTimeout(() => {
                setCodeSend(false)
            }, 5000)
        } catch {}
    }

    const handleRotate = () => {
        let newRotateValue =
            profilePhotoRotation === null || profilePhotoRotation === undefined
                ? 0
                : profilePhotoRotation
        newRotateValue += 90
        if (newRotateValue >= 360) {
            newRotateValue = 0
        }
        updateKey('profilePhotoRotation', newRotateValue)
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer)
        }
    }, [])
    // Todo: improve this, please, due to the inconsistencies with
    // The backend i had to implemente this validation, for some reasons users at the workflow
    // 4 end beyond should already have a profile photo uploaded
    const workflowsToWarningForImage = [5]
    return loading ? (
        <GeneralLoader />
    ) : (
        <>
            <Container fluid>
                <Row>
                    <Col xs="12" sm="8">
                        <h3>{t('detail.userActions')}</h3>
                        <div className="btn-group mb-4">
                            {/* <Button color="default" icon="pencil-alt">
                                {t('detail.editUser')}
                            </Button> */}
                            <Button
                                color="default"
                                icon="cogs"
                                onClick={() => setOpenChangeState(true)}
                            >
                                {t('detail.changeStatus')}
                            </Button>
                            <Button
                                color="default"
                                icon="paper-plane"
                                onClick={() => handleSendCode()}
                            >
                                {t('detail.resendCode')}
                            </Button>
                        </div>
                        {codeSend && (
                            <Alert>Hemos enviado de nuevo el código de verificación al triko</Alert>
                        )}
                    </Col>
                    <Col xs="12" sm="4">
                        {/* <h3 className="text-danger">{t('detail.dangerZone')}</h3> */}
                        <div className="btn-group mb-4">
                            {/* <Button variant="warning" icon="times">
                                {t('detail.rejectTriko')}
                            </Button>
                            <Button icon="ban" variant="danger">
                                {t('detail.deactivateAccount')}
                            </Button> */}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" lg="5" className="pt-10">
                        <Card>
                            <CardBody>
                                {!photoUrl && workflowsToWarningForImage.includes(workflow?.id) ? (
                                    <Alert color="danger">{t('detail.error.photo')}</Alert>
                                ) : (
                                    <>
                                        {photoUrl && [2, 3, 4, 5].includes(workflow?.id) ? (
                                            <div className="text-center">
                                                <Zoom>
                                                    <ProfilePhoto
                                                        style={{
                                                            transform: `rotate(${profilePhotoRotation}deg)`,
                                                            backgroundImage: `url(${photoUrl})`
                                                        }}
                                                    />
                                                </Zoom>
                                                <div className="text-center mt-4">
                                                    <Button variant="info" onClick={handleRotate}>
                                                        {t('detail.rotateImage')}
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <Alert>{t('detail.photoNotUploaded')}</Alert>
                                        )}
                                    </>
                                )}
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardBody>
                                <h4 className="text-center mt-8">{t('detail.trikoTimeLine')}</h4>
                                <TrikoTimeline user={trikoData?.user} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" lg="7" className="pt-10">
                        <Card>
                            <CardBody>
                                <UserInfo
                                    trikoID={trikoId}
                                    userID={user?.id}
                                    createdAt={created_at}
                                    email={user?.email}
                                    firstName={firstname}
                                    lastName={lastname}
                                    phoneNumber={user?.phonenumber}
                                    verifyCode={user?.attributes?.verifycode}
                                    workflow={
                                        workflows[
                                            WorkflowMapIds[user?.attributes?.workflow_transition_id]
                                        ]
                                    }
                                />
                                <PersonalInfo
                                    idNumber={idnumber}
                                    firstName={firstname}
                                    lastName={lastname}
                                    birthDate={birthdate}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {openChangeState && Boolean(workflow) && (
                <ChangeTrikoStatus
                    trikoId={trikoId}
                    currentWorkflow={workflow}
                    onCancel={() => setOpenChangeState(false)}
                    onSaved={() => {
                        setOpenChangeState(false)
                        refresh()
                    }}
                />
            )}
            {updating && <LoadingCurtain />}
        </>
    )
}

export default AboutTheTriko
