import jarvisConfig from '@config/jarvis.json'
import JarvisFetch from './jarvis-fetch'

class JarvisService {
    private fetcher: JarvisFetch

    constructor() {
        const { server, endpoints } = jarvisConfig
        this.fetcher = new JarvisFetch({
            server,
            endpoints
        })
    }

    public async getTrikosPerService() {
        const response = await this.fetcher.fetchData('trikos.per-service')
        return (response as any).data
    }

    public async getTrikosPerCity() {
        const response = await this.fetcher.fetchData('trikos.per-city')
        return (response as any).data
    }
    public async getClientsPerCity() {
        const response = await this.fetcher.fetchData('clients.per-city')
        return (response as any).data
    }

    public async getTrikosPerState() {
        const response = await this.fetcher.fetchData('trikos.per-state')
        return (response as any).data
    }

    public async getRequestsPerDate(dateFrom: string, dateTo: string) {
        const response = await this.fetcher.fetchData('requests.per-date', {
            from: dateFrom,
            to: dateTo
        })
        return (response as any).data
    }
}

const JarviceInstance = new JarvisService()

export default JarviceInstance
