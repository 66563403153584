import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

const getHeight = (height: string): string => {
    switch (height) {
        case 'xs':
            return '50px'
        case 'sm':
            return '100px'
        case 'md':
            return '120px'
        case 'lg':
            return '140px'
        case 'xl':
            return '160px'
        default:
            return '100px'
    }
}

export const ColumnLoader = styled(Skeleton)`
    width: 40px;
    margin-right: 10px;
    height: ${(props) => getHeight(props.className as string)};
`

export const LoaderWrapperDiv = styled.div`
    background-color: rgba(235, 235, 235, 0.2);
    padding: 20px 60px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    border-radius: 20px;
`
