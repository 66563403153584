import styled from 'styled-components'

export const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff6f;
    top: 0;
    left: 0;
    display: flex;
    justify: center;
    align-items: center;
`
