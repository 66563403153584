import React from 'react'
import { RequiredDocType } from '../types'
import { Badge } from './styled'
import KnowledgeTestRender from './document-renders/KnowledgeTest'
import {
    AddressesRender,
    FileViewRenderer,
    LicenseRender,
    AvailabilityRender,
    BankInformationRender,
    ServiceRatesRender,
    BackgroundCheckRender,
    ProfilePhotoRender
} from './document-renders'
import { Card, CardBody, CardFooter } from 'reactstrap'
import LoadingCurtain from '@core/loaders/loader-curtain'
import { Button } from '@alejandro.devop/bt-components'
import DataProvider from '@core/providers/data-provider'
import ListLoader from '@core/loaders/list-loader'
import VSARender from './document-renders/VSARender'
import { useTranslate } from '@core/hooks'
import { usePatch } from '@alejandro.devop/react-json-api-client'

interface DocumentDetailProps {
    docItem: RequiredDocType
    updateDocument: (d: RequiredDocType) => void
    onDocumentUpdated: () => void
}

export const WORKFLOW_DOC_PENDING = 18
export const WORKFLOW_DOC_APPROVED = 19
export const WORKFLOW_DOC_REJECTED = 20
export const WORKFLOW_TRIKO_REJECTED = 0

const DocumentDetail: React.FC<DocumentDetailProps> = ({
    docItem,
    updateDocument,
    onDocumentUpdated
}) => {
    const { requirement, workflow_transition_id } = docItem.attributes
    const [sendRequest, loading] = usePatch<AvailableEndpointsType>(
        'requiredDocumentation.approveRejectDocument',
        {
            replacements: {
                triko_id: docItem.attributes.triko_id
            }
        }
    )
    const t = useTranslate()
    const lang = 'es'
    const getContent = () => {
        if (Boolean(requirement?.is_knowledge_test)) {
            return <KnowledgeTestRender docItem={docItem} />
        } else if (Boolean(requirement?.is_bank)) {
            return (
                <DataProvider url="general.getBacks" LoaderComponent={ListLoader} key="banks">
                    <BankInformationRender docItem={docItem} />
                </DataProvider>
            )
        } else if (requirement?.path === 'billing-address') {
            return (
                <DataProvider
                    url="trikos.detail"
                    replacements={{ id: docItem.attributes.triko_id }}
                    include={['trikoaddress']}
                >
                    <AddressesRender />
                </DataProvider>
            )
        } else if (Boolean(requirement?.is_file)) {
            return <FileViewRenderer docItem={docItem} />
        } else if (requirement?.name.es === 'RUT') {
            return <FileViewRenderer docItem={docItem} />
        } else if (requirement?.is_profile_photo) {
            return <ProfilePhotoRender docItem={docItem} />
        } else if (requirement?.is_license) {
            return <LicenseRender docItem={docItem} />
        } else if (requirement?.is_vsa_interview) {
            return <VSARender />
        } else if (requirement?.is_background_check) {
            return <BackgroundCheckRender docItem={docItem} onUpdatedDocument={updateDocument} />
        } else if (requirement?.path === 'my-services') {
            return (
                <DataProvider
                    url="trikos.servicesPortfolio"
                    filters={{ triko_id: docItem.attributes.triko_id }}
                    key="services"
                    LoaderComponent={ListLoader}
                >
                    <ServiceRatesRender docItem={docItem} />
                </DataProvider>
            )
        } else if (requirement?.path === 'my-availability') {
            return (
                <DataProvider
                    key="availability"
                    url="trikos.getAvailability"
                    replacements={{ id: docItem.attributes.triko_id }}
                    LoaderComponent={ListLoader}
                >
                    <AvailabilityRender />
                </DataProvider>
            )
        } else {
            return <></>
        }
    }

    const currentStatus = workflow_transition_id.toString()

    const getStatus = () => {
        if (currentStatus === '18') {
            return <Badge color="info">{t('documents.pending')}</Badge>
        } else if (currentStatus === '19') {
            return <Badge color="success">{t('documents.approved')}</Badge>
        } else if (currentStatus === '20') {
            return <Badge color="danger">{t('documents.rejected')}</Badge>
        }
        return 'undefined'
    }

    const changeWorkflow = async (newWorkflow: number) => {
        try {
            await sendRequest<PayloadType<{ workflow_transition_id: any }>>({
                data: {
                    type: 'requireddocumentations',
                    id: docItem.id,
                    attributes: {
                        workflow_transition_id: newWorkflow
                    }
                }
            })

            onDocumentUpdated()
        } catch (e) {
            // Todo handle error
        }
    }

    const handleApprove = async () => {
        await changeWorkflow(WORKFLOW_DOC_APPROVED)
    }

    const handleReject = async () => {
        await changeWorkflow(WORKFLOW_DOC_REJECTED)
    }
    console.log('Document: ', docItem)
    return (
        <Card>
            <CardBody>
                <h4>{requirement?.name[lang]}</h4>
                {getStatus()}
                <hr />
                {getContent()}
                {loading && <LoadingCurtain text={t('detail.updatingDocument')} />}
                <CardFooter>
                    <div className="flex flex-row justify-end">
                        {!loading ? (
                            <>
                                <Button className="mr-4" variant="success" onClick={handleApprove}>
                                    {t('documents.approveDocument')}
                                </Button>
                                <Button variant="danger" onClick={handleReject}>
                                    {t('documents.rejectDocument')}
                                </Button>
                            </>
                        ) : (
                            <div className="flex-1"></div>
                        )}
                    </div>
                </CardFooter>
            </CardBody>
        </Card>
    )
}

export default DocumentDetail
