import React, { useState } from 'react'
import LoginForm from './LoginForm'
import { usePost } from '@alejandro.devop/react-json-api-client'
import { AuthPayloadSchema, UserLoggedSchema } from '@schemas/auth.schema'
import useSession from '@session/hooks'

/**
 * Renders the Login user Page
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @type Page
 * @returns {*}
 */
const LoginPage: React.FC = () => {
    const { setAll } = useSession()
    const [sendRequest, loading] =
        usePost<AvailableEndpointsType, { infosesion: UserLoggedSchema }, AuthPayloadSchema>(
            'security.auth'
        )
    const [authError, setAuthError] = useState(false)
    const handleAuth = async (data: AuthPayloadSchema) => {
        try {
            const { infosesion } = await sendRequest(data, {
                form: true
            })
            if (infosesion) {
                const { name, token_info, user_email, user_id } = infosesion
                setAll({
                    isLogged: true,
                    token: token_info.accessToken,
                    tokenType: 'Bearer',
                    user: {
                        user_id,
                        user_email,
                        name
                    }
                })
            } else {
                setAuthError(true)
            }
        } catch (err) {
            setAuthError(true)
        }
    }
    const toggleError = () => null

    return (
        <LoginForm
            authError={authError}
            loading={loading}
            onSubmit={handleAuth}
            toggleError={toggleError}
        />
    )
}

export default LoginPage
