export default {
    '21': 'service.Pending',
    '22': 'service.Accepted',
    '23': 'service.Payment',
    '24': 'service.OnMyWay',
    '25': 'service.OnYourDoor',
    '26': 'service.ConfirmStarting',
    '27': 'service.Started',
    '28': 'service.ConfirmFinished',
    '29': 'service.QualifyServiceClient',
    '30': 'service.Finish',
    '31': 'service.Cancel',
    '38': 'service.QualifyServiceTriko',
    '42': 'service.WaitingForTriko',
    '43': 'service.GoingToShop',
    '44': 'service.InTheShop',
    '45': 'service.Shopping',
    '46': 'service.WaitingForClient',
    '47': 'service.PayingTheCart',
    '48': 'service.PayingTheOrder',
    '49': 'service.ConfirmPayment',
    '50': 'service.OnGoing'
}
