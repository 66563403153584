import * as React from 'react'
import Breadcrumbs from '@core/components/breadcrumbs'
import { Container, Col, Row } from 'reactstrap'
import TrikosPerState from '@core/components/charts/trikos-per-state'
import TrikosVSClients from '@core/components/charts/trikos-vs-clients/TrikosVsClients'
import TrikosPerCity from '@core/components/charts/trikos-per-city/TrikosPerCity'
import ClientsPerCity from '@core/components/charts/clients-per-city/TrikosPerCity'

const HomePage: React.FC = () => {
    return (
        <div>
            <Breadcrumbs />
            <Container fluid>
                <Row>
                    <Col xs="12" lg="6" xl="4">
                        <TrikosPerState />
                    </Col>
                    <Col xs="12" lg="6" xl="8">
                        <Container>
                            <Row>
                                <Col xs="12" lg="6">
                                    <TrikosPerCity />
                                </Col>
                                <Col xs="12" lg="6">
                                    <ClientsPerCity />
                                </Col>
                            </Row>
                        </Container>
                        <TrikosVSClients />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HomePage
