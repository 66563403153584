import React from 'react'
import Panel from '@core/panel/Panel'
import Loader from './Loader'

interface ChartBaseProps {
    title: string
    children: React.ReactNode
    loading?: boolean
}

const ChartBase: React.FC<ChartBaseProps> = ({ children, loading, title }) => {
    return <Panel title={title}>{loading ? <Loader /> : children}</Panel>
}

export default ChartBase
