import styled from 'styled-components'

export const TimeLineIconItemWrapper = styled.div`
    background-color: #43a047;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    z-index: 3;
    & .tm-icon {
        color: #fff;
        font-size: 20px;
    }
`

export const TimeLineBlock = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 80px;
`
export const TimeLineTimeWrapper = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    & .date {
        font-size: 10px;
    }
    & .time {
        font-size: 12px;
        font-weight: 600;
    }
`
export const TimeLineIconWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 3;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 5px;
        background-color: #43a0478f;
        z-index: 1;
    }
`
export const TimeLineContentWrapper = styled.div`
    flex: 6;
    padding-left: 20px;
    padding-top: 5px;
    flex-wrap: wrap;
`
export const ContentWrapper = styled.div`
    & .time-line-wrapper:last-child {
        .timeline-icon:before {
            background-color: transparent;
        }
    }
`
