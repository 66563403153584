import { useContext } from 'react'
import { NavigationContext } from './app-navigation/NavigationContext'
import { urlsMap } from '@config/navigation'
import {
    HookReturnType,
    NavigationConfig,
    RouteResultType,
    SelectedConfig,
    NavigationContextType
} from './app-navigation/types'

/**
 * This function allows to create the hook which will provide the application navigation
 * 1. The Hook resolves whether the config it's a function or a plain object
 * 2. The Hook determines which group should render by finding the one which condition it's true
 *      Note: Only the first group with condition true will be rendered, if there are other conditions in true they will be
 *      ignored.
 * 2.1. If the config is a function the hook will invoke it and passed the variables defined by you.
 * 3. The routes are mapped so a loop can be used to render them.
 * @param navigationConfig
 */
export const makeNavigationStates = (
    navigationConfig: NavigationConfig // Step 1, the user should pass it the groups config (Usually comes from @config/navigation-states.tsx)
): ((c?) => HookReturnType) => {
    // We use a inline to avoid linter warnings but this is the hook function
    return (hookConf?): HookReturnType => {
        const resolveUrl = useUrlResolver()
        // Step 2 and 2.1. The navigation config it's read
        const configStates =
            typeof navigationConfig === 'function' ? navigationConfig(hookConf) : navigationConfig
        const configKeys: string[] = Object.keys(configStates)
        const selectedRouteKey = configKeys.find(
            (key: string) => configStates[key].condition === true
        )
        let selectedConfig: SelectedConfig = {}
        if (selectedRouteKey && configStates[selectedRouteKey]) {
            selectedConfig = configStates[selectedRouteKey]
        }
        // Step 3, the routes are mapped to return an array
        if (selectedConfig) {
            const { config = {}, layout } = selectedConfig
            const { routes, initial } = config
            const configKeys = Object.keys(routes)
            const processedRoutes = configKeys.map((key: string): RouteResultType => {
                const route = routes[key]
                if (typeof route === 'function') {
                    return {
                        path: resolveUrl(key),
                        component: route
                    }
                } else if (typeof route === 'object') {
                    const { component, ...options } = route
                    return {
                        path: resolveUrl(key),
                        component,
                        ...options
                    }
                } else {
                    return {}
                }
            })
            return { initial: resolveUrl(initial), routes: processedRoutes, layout }
        } else {
            return {}
        }
    }
}

/**
 * This hook allows to connect with the navigation provider functions
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
export const useNavigationProvider = (): NavigationContextType => {
    const { goTo, redirect } = useContext(NavigationContext) as NavigationContextType
    return {
        goTo,
        redirect
    }
}

/**
 * This function allows to get the path mapped in the @config/navigation/urls
 */
export const useUrlResolver =
    () =>
    (key: string | undefined): string =>
        key && urlsMap[key] ? urlsMap[key] : key
