import React, { useState } from 'react'
import { Provider } from './translation-context'
import * as locales from '@locales'

interface TranslationProviderProps {
    children: React.ReactNode
    defaultLang?: keyof typeof locales
}

const TranslationProvider: React.FC<TranslationProviderProps> = ({ children, defaultLang }) => {
    const [currentLang, setCurrentLang] = useState<keyof typeof locales>(defaultLang || 'es')

    const onChangeLanguage = (lang: keyof typeof locales): void => {
        setCurrentLang(lang)
    }

    const currentDictionary = locales[currentLang]
    return (
        <Provider value={{ dictionary: currentDictionary, onChangeLanguage }}>{children}</Provider>
    )
}

TranslationProvider.defaultProps = {
    defaultLang: 'es'
}

export default TranslationProvider
