import * as React from 'react'
import useAppSettings from '@core/app-context/hooks'
import { BrowserConfig } from '../commons'

interface Props {
    children?: React.ReactNode
}

/**
 * This component renders a clean layout for the login view
 * @version 1.0.0
 * @author Alejandro <alejandro.devop@gmail.com>
 * @param children
 * @constructor
 */
const LoginLayout: React.FC<Props> = ({ children }) => {
    const { pageContentFullWidth, pageContentInverseMode, pageContentClass } = useAppSettings()
    return (
        <>
            <BrowserConfig />
            <div
                className={
                    'content ' +
                    (pageContentFullWidth ? 'content-full-width ' : '') +
                    (pageContentInverseMode ? 'content-inverse-mode ' : '') +
                    pageContentClass
                }
            >
                {children}
            </div>
        </>
    )
}

export default LoginLayout
