import React from 'react'
import { es } from '@locales'
import { TranslationContextType } from './translation.types'

export const TranslationContext = React.createContext<TranslationContextType>({
    dictionary: es
})

export const Provider = TranslationContext.Provider
export const Consumer = TranslationContext.Consumer
