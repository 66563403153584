import React, { useState } from 'react'
import { useCallback } from 'react'
import { Table } from 'reactstrap'
import { RequiredDocType } from '../../types'
import { DropZone, DropZoneInput } from './styled'
import FileDataPreviewer from './FileDataPreviewer'
import { useDropzone } from 'react-dropzone'
import { Icon, Button } from '@alejandro.devop/bt-components'
import LoadingCurtain from '@core/loaders/loader-curtain'
import { usePost } from '@alejandro.devop/react-json-api-client'
import { useTranslate } from '@core/hooks'

const BackgroundCheckRender: React.FC<{
    docItem: RequiredDocType
    onUpdatedDocument: (d: RequiredDocType) => void
}> = ({ docItem, onUpdatedDocument }) => {
    const [fileMeta, setFileMeta] = useState<{
        type?: string
        size?: number
        extension?: string
    }>({})
    const [sendRequest, loading] = usePost<AvailableEndpointsType, RequiredDocType>(
        'requiredDocumentation.attachFile',
        {
            replacements: {
                reqId: docItem.id
            }
        }
    )
    const t = useTranslate()
    const [fileData, setFileData] = useState<any>()

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const [file] = acceptedFiles
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setFileData(reader.result)
        }
        reader.onerror = () => null
        const { type, size } = file
        const [, extension] = type.split('/')
        setFileMeta({
            extension,
            type,
            size
        })
    }, [])
    const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop })

    const handleUploadFile = async () => {
        try {
            const response = await sendRequest<
                PayloadType<{
                    file: any
                }>
            >({
                data: {
                    id: docItem.id,
                    type: 'requireddocumentations',
                    attributes: {
                        file: fileData
                    }
                }
            })
            clearFile()
            onUpdatedDocument({
                ...docItem,
                attributes: {
                    ...docItem.attributes,
                    attachments: response.attributes.attachments
                }
            })
        } catch (error) {
            // Todo: handle error...
        }
    }

    const clearFile = () => {
        setFileData(null)
        setFileMeta({})
    }
    return (
        <>
            {!fileData && (
                <DropZone {...getRootProps()}>
                    <DropZoneInput {...getInputProps()} />
                    <Icon type="inbox" className="icon" />
                    <p className="text-lg text-center">
                        {isDragActive ? t('general.dropdownActive') : t('general.dropdownInactive')}
                    </p>
                </DropZone>
            )}
            {fileData && (
                <>
                    <FileDataPreviewer
                        fileData={fileData}
                        extension={fileMeta.extension}
                        size={fileMeta.size}
                    />
                    <div className="flex flex-col px-16 py-12 mb-6">
                        <Button onClick={handleUploadFile} className="mb-2" variant="success">
                            {t('general.uploadFile')}
                        </Button>
                        <Button variant="info" onClick={clearFile}>
                            {t('general.pickAnother')}
                        </Button>
                    </div>
                    {loading && <LoadingCurtain text="Uploading your file" />}
                </>
            )}
            {docItem.attributes.attachments.length > 0 && (
                <Table>
                    <tbody>
                        {docItem.attributes.attachments.map((item, key) => (
                            <tr key={`item-${key}`}>
                                <td>
                                    <Icon type="file-alt" />
                                </td>
                                <td>{item.uuid_file}</td>
                                <td>
                                    <a href={item.file} download target="_blank">
                                        <Button variant="info">{t('detail.downloadFile')}</Button>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    )
}

export default BackgroundCheckRender
