import * as React from 'react'
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import useAppSettings from '@core/app-context/hooks'
const { useState } = React

type collapsablesType = {
    id: number
    collapse: boolean
}

const SidebarRight: React.FC = () => {
    const { pageTwoSidebar } = useAppSettings()
    const [collapsables, setCollapsables] = useState<collapsablesType[]>([
        { id: 1, collapse: true },
        { id: 2, collapse: false },
        { id: 3, collapse: false },
        { id: 4, collapse: false },
        { id: 5, collapse: false },
        { id: 6, collapse: false },
        { id: 7, collapse: false }
    ])

    const toggleCollapse = (index: number) => {
        const newArray: collapsablesType[] = []
        for (const collapseObj of collapsables) {
            if (collapseObj.id === index) {
                collapseObj.collapse = !collapseObj.collapse
            } else {
                collapseObj.collapse = false
            }
            newArray.push(collapseObj)
        }
        setCollapsables(newArray)
    }

    if (!pageTwoSidebar) {
        return null
    }

    return (
        <>
            <div id="sidebar-right" className="sidebar sidebar-right">
                <div className="overflow-scroll height-full">
                    <ul className="nav">
                        <li className="nav-widget p-0">
                            <p className="text-white m-0 p-15">Accordion</p>
                            <div id="accordion" className="accordion">
                                {collapsables.map((value, i) => (
                                    <Card className="bg-dark text-white border-0" key={i}>
                                        <CardHeader
                                            className={
                                                'card-header bg-dark-darker text-white pointer-cursor border-0 ' +
                                                (!value.collapse ? 'collapsed ' : '')
                                            }
                                            onClick={() => toggleCollapse(value.id)}
                                        >
                                            <i className="fa fa-circle text-teal f-s-8 mr-2" />
                                            Accordion Item #{value.id}
                                        </CardHeader>
                                        <Collapse isOpen={value.collapse}>
                                            <CardBody className="p-15">
                                                Anim pariatur cliche reprehenderit, enim eiusmod
                                                high life accusamus.
                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                ))}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="sidebar-bg sidebar-right" />
        </>
    )
}

export default SidebarRight
