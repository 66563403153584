import { useContext } from 'react'
import { DataProviderContext } from '../../providers/data-provider/Context'

const useDataProvider = <DataType>(): {
    providedData: DataType
    loadingData: boolean
    refresh?: () => Promise<any>
} => {
    const { data, loading, refresh } = useContext(DataProviderContext)
    return { providedData: data, loadingData: loading || false, refresh }
}

export default useDataProvider
