import React, { useState } from 'react'
import SmartGrid from '@alejandro.devop/bootstrap-smart-grid'
import Panel from '@core/panel'
import { useTranslate } from '@core/hooks'
import { DictionaryKeyType } from '@core/translation-provider/translation.types'
import Breadcrumbs from '@core/components/breadcrumbs'
import config from './page.config'
import PerfectScrollbar from 'react-perfect-scrollbar'
import gridconfig from './grid.config'
import { TrikoDetail } from 'components'

const RequiredDocumentsPage: React.FC = () => {
    const { breadCrumbs } = config
    const t = useTranslate('services')
    const [selectedTriko, setSelectedTriko] = useState<any>()
    const handleActionCall = (action: string, record: any) => {
        if (action === 'view') {
            setSelectedTriko(record?.attributes?.triko?.id)
        }
    }
    return (
        <>
            <Breadcrumbs paths={breadCrumbs} />
            <Panel title="service_requests">
                <PerfectScrollbar>
                    <SmartGrid
                        headerTextProcessor={(k: string) => t(k as DictionaryKeyType)}
                        textProcessor={(k: string, replacements) => {
                            const { key, value } = replacements || {}
                            const valuesToReplace = {}
                            if (key) {
                                valuesToReplace[key] = value
                            }
                            return t(k as DictionaryKeyType, {
                                ignoreScope: true,
                                replacements: valuesToReplace
                            })
                        }}
                        config={gridconfig}
                        onAcctionCalled={handleActionCall}
                    />
                </PerfectScrollbar>
            </Panel>
            {Boolean(selectedTriko) && (
                <TrikoDetail trikoId={selectedTriko} onClose={() => setSelectedTriko(null)} />
            )}
        </>
    )
}

export default RequiredDocumentsPage
