import useSession from '@session/hooks'
import { useState } from 'react'

type TrikoCacheType = 'profilePhotoRotation'
// [{ profilePhotoRotation: number }, (k: TrikoCacheType, value: any) => void]
type UseCacheConfigType = (K: any) => { data: any; updateKey: any }

const useCacheConfig: UseCacheConfigType = (trikoId: string) => {
    const { store, setKey } = useSession()
    const { cache } = store
    const { trikos } = cache || {}
    const trikoConfig = (trikos || {})[trikoId]
    const { config } = trikoConfig || {}
    const [givenConfig, setGivenConfig] = useState(config || {})

    const updateKey = (k: TrikoCacheType, value: any) => {
        const newGivenConfig = { ...givenConfig, [k]: value }
        setGivenConfig(newGivenConfig)
        setKey('cache', {
            ...cache,
            trikos: {
                ...trikos,
                [trikoId]: {
                    ...trikoConfig,
                    config: {
                        ...newGivenConfig,
                        [k]: value
                    }
                }
            }
        })
    }
    return { data: givenConfig, updateKey }
}

export default useCacheConfig
