import React from 'react'
import { useGet } from '@alejandro.devop/react-json-api-client'
import { Container, Card, CardBody, Col, Row, Alert } from 'reactstrap'
import GeneralLoader from '../../loader/GeneralLoader'
import { useMemo } from 'react'
import WorkflowMapIds from '@constants/workflows-map'
import workflows from '@locales/es/workflows.json'
import UserInfo from './UserInfo'
import { Button } from '@alejandro.devop/bt-components'
import PersonalInfo from 'components/triko-detail/panels/about-triko/PersonalInformation'
import { ProfilePhoto } from './styled'
import { useDetailContext } from '../../hooks'
import useCacheConfig from '@core/hooks/use-cache-config/useCacheConfig'
import Zoom from 'react-medium-image-zoom'
import { TrikoDataType } from './types'
import { useTranslate } from '@core/hooks'

const AboutTheClient: React.FC<{ onLoadInformation: (d: any) => void }> = ({
    onLoadInformation
}) => {
    const { clientId } = useDetailContext()
    const t = useTranslate()
    const {
        data: { profilePhotoRotation },
        updateKey
    } = useCacheConfig(clientId)
    const [data, { loading }] = useGet<AvailableEndpointsType>('clients.detail', {
        onCompleted: (d) => {
            onLoadInformation(d)
        },
        replacements: {
            id: clientId
        },
        include: ['user:users->user|user_id']
    })
    const clientData = useMemo(() => {
        return {
            id: (data as any)?.id,
            ...(data as any)?.attributes
        }
    }, [data])
    const { user, photoUrl, personalInformation, created_at, workflow } = (clientData ||
        {}) as TrikoDataType
    const { firstname, lastname, birthdate, idnumber } = personalInformation || {}

    const handleRotate = () => {
        let newRotateValue =
            profilePhotoRotation === null || profilePhotoRotation === undefined
                ? 0
                : profilePhotoRotation
        newRotateValue += 90
        if (newRotateValue >= 360) {
            newRotateValue = 0
        }
        updateKey('profilePhotoRotation', newRotateValue)
    }
    // Todo: improve this, please, due to the inconsistencies with
    // The backend i had to implemente this validation, for some reasons users at the workflow
    // 4 end beyond should already have a profile photo uploaded
    const workflowsToWarningForImage = [5]
    return loading ? (
        <GeneralLoader />
    ) : (
        <Container fluid>
            <Row>
                <Col xs="12" lg="5" className="pt-10">
                    <Card>
                        <CardBody>
                            {!photoUrl && workflowsToWarningForImage.includes(workflow?.id) ? (
                                <Alert color="danger">{t('detail.error.photo')}</Alert>
                            ) : (
                                <>
                                    {photoUrl && [2, 3, 4, 5].includes(workflow?.id) ? (
                                        <div className="text-center">
                                            <Zoom>
                                                <ProfilePhoto
                                                    style={{
                                                        transform: `rotate(${profilePhotoRotation}deg)`,
                                                        backgroundImage: `url(${photoUrl})`
                                                    }}
                                                />
                                            </Zoom>
                                            <div className="text-center mt-4">
                                                <Button variant="info" onClick={handleRotate}>
                                                    {t('detail.rotateImage')}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <Alert>{t('detail.photoNotUploaded')}</Alert>
                                    )}
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" lg="7" className="pt-10">
                    <Card>
                        <CardBody>
                            <UserInfo
                                clientID={clientId}
                                userID={user?.id}
                                createdAt={created_at}
                                email={user?.email}
                                firstName={firstname}
                                lastName={lastname}
                                phoneNumber={user?.phonenumber}
                                verifyCode={user?.attributes?.verifycode}
                                workflow={
                                    workflows[
                                        WorkflowMapIds[user?.attributes?.workflow_transition_id]
                                    ]
                                }
                            />
                            <PersonalInfo
                                idNumber={idnumber}
                                firstName={firstname}
                                lastName={lastname}
                                birthDate={birthdate}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default AboutTheClient
