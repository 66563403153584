import * as React from 'react'
import { useHistory } from 'react-router-dom'

export const NavigationContext = React.createContext({})
const Provider = NavigationContext.Provider

interface Props {
    children: React.ReactNode
    navConfig?: { [key: string]: any } | null
}

/**
 * This component renders the navigation context, passes values which can be used by the navigation children
 * @author Alejandro <alejandro.devop@gmail.com>
 * @version 1.0.0
 * @param children
 * @constructor
 */
const NavigationProvider: React.FC<Props> = ({ children }) => {
    const history = useHistory()
    const goTo = (path: string, state: { [key: string]: any }) => history.push(path, state)
    const redirect = (path: string, state: { [key: string]: any }) => history.replace(path, state)
    return (
        <Provider
            value={{
                goTo,
                redirect
            }}
        >
            {children}
        </Provider>
    )
}

export default React.memo(NavigationProvider)
