import React from 'react'
import { Container, Badge, Col, Row, Card, CardBody, CardHeader, Table, Alert } from 'reactstrap'
import { useGet } from '@alejandro.devop/react-json-api-client'
import ServicesLoader from './ServicesLoader'
import { Button } from '@alejandro.devop/bt-components'
import { TrikoServiceObjectType } from './types'
import ServiceCard from 'components/triko-detail/panels/services/ServiceCard'
import { useDetailContext } from '../../hooks'
import { useTranslate } from '@core/hooks'

const ServicesPanel: React.FC = () => {
    const t = useTranslate()
    const { trikoId } = useDetailContext()
    const [data, { loading, refresh }] = useGet<AvailableEndpointsType, TrikoServiceObjectType[]>(
        'trikos.services',
        {
            filters: {
                triko_id: trikoId
            }
        }
    )
    const toApprove = (data || [])?.reduce((accu, item) => {
        return accu + (item.attributes.workflow_transition_id === 51 ? 1 : 0)
    }, 0)
    const approved = (data || [])?.reduce((accu, item) => {
        accu += item.attributes.workflow_transition_id === 52 ? 1 : 0
        return accu
    }, 0)
    return (
        <Container>
            <Row>
                <Col xs="12" lg="4">
                    <Card>
                        <CardHeader>{t('detail.servicesPanel')}</CardHeader>
                        <CardBody>
                            <div className="flex flex-row justify-end mb-4">
                                <Button variant="info" onClick={() => refresh()}>
                                    {t('general.refresh')}
                                </Button>
                            </div>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>{t('detail.servicesApproved')}</td>
                                        <td>
                                            <Badge color="success">{approved}</Badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('detail.servicesToApprove')}</td>
                                        <td>
                                            <Badge color="warning">{toApprove}</Badge>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" lg="8">
                    {loading && <ServicesLoader />}
                    {!loading && (!data || data.length === 0) && (
                        <Alert color="warning">{t('detail.noServicesToList')}</Alert>
                    )}
                    {!loading &&
                        data?.map((item, key) => (
                            <ServiceCard key={`service-card-${key}`} service={item} />
                        ))}
                </Col>
            </Row>
        </Container>
    )
}

export default ServicesPanel
