import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import { TextRowLoader } from './styled'
import _ from 'lodash'

const PersonalInformationLoader: React.FC = () => {
    return (
        <div className="w-full">
            <div className="px-12 mb-4">
                <TextRowLoader />
            </div>
            <Container fluid>
                {_.times(12, (item) => (
                    <Row key={`pi-${item}`}>
                        <Col xs="4">
                            <TextRowLoader />
                        </Col>
                        <Col xs="8">
                            <TextRowLoader />
                        </Col>
                    </Row>
                ))}
            </Container>
        </div>
    )
}

export default PersonalInformationLoader
