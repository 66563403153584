import React from 'react'
import { IconLoader, IconWrapper, TextLine, TextWrapper } from './styled'
import _ from 'lodash'

const DocumentLoader: React.FC = () => {
    return (
        <>
            <h4 className="text-center">Required documentation</h4>
            {_.times(10, (key) => (
                <div key={`loader-${key}`} className="flex flex-row mb-12">
                    <IconWrapper>
                        <IconLoader />
                    </IconWrapper>
                    <TextWrapper>
                        <TextLine />
                        <TextLine />
                        <TextLine />
                        <TextLine />
                        <TextLine />
                    </TextWrapper>
                </div>
            ))}
        </>
    )
}

export default DocumentLoader
