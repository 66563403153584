import React from 'react'
import { SkeletonRouded, SkeletonText } from './styled'
import _ from 'lodash'

const ListLoader: React.FC<{
    lines?: number
}> = ({ lines }) => {
    return (
        <>
            {_.times(lines || 2, (key) => {
                return (
                    <div className="flex flex-row" key={`skeleton-${key}`}>
                        <SkeletonRouded />
                        <div className="flex-1 pl-8">
                            <SkeletonText />
                            <SkeletonText />
                        </div>
                    </div>
                )
            })}
        </>
    )
}

ListLoader.defaultProps = {
    lines: 5
}

export default ListLoader
