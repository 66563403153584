import React, { useState } from 'react'
import { useGet } from '@alejandro.devop/react-json-api-client'
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem } from 'reactstrap'
import { ImageIcon, TextWrapper, ItemWrapper } from './styled'
import { useTranslate } from '@core/hooks'
import { IconButton } from '@alejandro.devop/bt-components'
import { ServiceTypeSchema } from '@schemas/servicetype.schema'
import ListLoader from './ListLoader'
import { ServiceSchema } from '@schemas/service.schema'

const ServicesList: React.FC<{
    categoryId?: string | number
    onSelectService: (s: ServiceSchema) => void
}> = ({ categoryId, onSelectService }) => {
    const selectedLang = 'es'
    const [selectedCategory, setSelectedCategory] = useState<ServiceTypeSchema | null>(null)
    const t = useTranslate()
    /**
     * ToDo: Please, the backend should open this to more then 100 records per page
     */
    const [data, { loading, meta }] = useGet<AvailableEndpointsType>('services.list', {
        currentPage: 1,
        pageSize: 100,
        filters: {
            type: `[${categoryId}]`
        }
    })
    const handleSelectItem = (item: ServiceTypeSchema) => {
        setSelectedCategory(item)
    }
    console.log('Data: ', data, t, selectedCategory)
    return (
        <Card>
            <CardHeader>{`${meta?.page.total || 0} Servicios en esta categoría`}</CardHeader>
            <CardBody>
                <ListGroup flush>
                    {loading && <ListLoader />}
                    {!loading &&
                        data &&
                        Array.isArray(data) &&
                        data.map((item: any, key) => (
                            <ListGroupItem
                                key={`key-${key}`}
                                action={true}
                                tag="div"
                                onClick={() => handleSelectItem(item)}
                            >
                                <ItemWrapper>
                                    <TextWrapper>
                                        <ImageIcon
                                            src={item.attributes.icon}
                                            alt={item.attributes.name[selectedLang]}
                                        />
                                        {item.attributes.name[selectedLang]}
                                    </TextWrapper>
                                    <IconButton
                                        icon="chevron-right"
                                        onClick={() => onSelectService(item)}
                                    />
                                </ItemWrapper>
                            </ListGroupItem>
                        ))}
                </ListGroup>
            </CardBody>
        </Card>
    )
}

export default ServicesList
