import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import SidebarProfile from './sidebar-profile'
import SidebarNav from './sidebar-nav'
import { Link } from 'react-router-dom'
import useAppSettings from '@core/app-context/hooks'

const SideBar: React.FC = () => {
    const { pageSidebarSearch, pageSidebarTransparent, toggleSidebarMinify, toggleMobileSidebar } =
        useAppSettings()
    return (
        <>
            <div
                id="sidebar"
                className={
                    'sidebar app-sidebar-content ' +
                    (pageSidebarTransparent ? 'sidebar-transparent' : '')
                }
            >
                <PerfectScrollbar
                    className="height-full app-sidebar-content"
                    options={{ suppressScrollX: true }}
                >
                    {!pageSidebarSearch && <SidebarProfile />}
                    <div className="height-full menu-content">
                        <SidebarNav />
                        <Link to="/" className="sidebar-minify-btn" onClick={toggleSidebarMinify}>
                            <i className="fa fa-angle-double-left" />
                        </Link>
                    </div>
                </PerfectScrollbar>
            </div>
            <div className="sidebar-bg" />
            <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar} />
        </>
    )
}

export default React.memo(SideBar)
