import PDFPreview from 'components/triko-detail/panels/required-doc/document-detail/document-renders/PDFPreview'
import React from 'react'
import { Alert } from 'reactstrap'
import { RequiredDocType } from '../../types'
import ImagePreview from './ImagePreview'

interface SocialSecurityProps {
    docItem: RequiredDocType
}

const allowedImageFormats = ['apng', 'jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp', 'heic']

const allowedDocFormats = ['pdf', 'doc', 'docx']

const FileViewRenderer: React.FC<SocialSecurityProps> = ({ docItem }) => {
    const { attributes } = docItem
    const { url_download_file } = attributes
    const files = (url_download_file || '').split(',')

    const extension: string = (() => {
        const regExp = new RegExp(
            `\.(${[...allowedImageFormats, ...allowedDocFormats].join('|')})`,
            'g'
        )
        const [, match] = (url_download_file || '').match(regExp) as string[]
        return match ? match.replace(/\./g, '') : ''
    })()

    return (
        <div>
            <Alert color="info">This document can be downloaded to view it's content</Alert>
            {files.map((fileUrl, key) => (
                <div key={`file-${key}`} className="mb-4">
                    {allowedImageFormats.includes(extension) && (
                        <ImagePreview imageUrl={fileUrl} allowViewer allowDownload />
                    )}
                </div>
            ))}
            {extension === 'pdf' && <PDFPreview url={url_download_file} />}
        </div>
    )
}

export default FileViewRenderer
